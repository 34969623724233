import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import StartupView from "@/views/StartupView.vue";
import TestView from "@/views/TestView.vue";
import frameCtrl from "@/utils/frameCtrl";
import modalCtrl from "@/utils/modalCtrl";
import tabCtrl from "@/utils/tabCtrl";

Vue.use(VueRouter);

const routes = [
	{
		path: "/startup",
		name: "startup",
		component: StartupView,
		meta: {
			title: "Cash Tracert",
		},
	},
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: {
			title: "Cash Tracert",
		},
	},
	{
		path: "/Test",
		name: "test",
		component: TestView,
		meta: {
			title: "Cash Tracert - Test View",
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.name == "test") {
		next();
		return;
	}
	if (to.name == "active") {
		next();
		return;
	}
	if (to.name !== "startup") {
		let isNeedInitial = false;
		if (!store.state.rootStore.isInitial) {
			isNeedInitial = true;
		}
		const userEntity = store.state.rootStore.userEntity;
		if (userEntity == null || !userEntity.IsLogin) {
			isNeedInitial = true;
		}

		if (isNeedInitial) {
			let queryTarget = JSON.parse(JSON.stringify(to.query));
			if (queryTarget.subframe) queryTarget.subframe = undefined;
			if (queryTarget.modalbox) queryTarget.modalbox = undefined;
			store.dispatch("rootStore/routerStartupBack", {
				toName: to.name,
				query: queryTarget,
			});
			next({ name: "startup" });
			return;
		}
	}
	next();
});
const DEFAULT_TITLE = "Cash Tracert";
router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
		frameCtrl.getFrameFromUrl();
		frameCtrl.getSplitFromUrl();
		modalCtrl.getModalBoxFromUrl();
		tabCtrl.getTabTargetFromUrl();
	});
});
export default router;
