<template src="./WindowFrame.html"></template>
  
<script>
export default {
	name: "WindowFrame",
	props: {},
	data() {
		return {
			baseWidth: 640,
			baseHeight: 400,
			baseTrans: 0.5,
			baseTiming: "",
			frameStatus: "",
			frameZoom: 0.9,
			framePointEvent: "none",
			frameOpacity: 0,
			frameTrans: 0.5,
			frameTiming: "",
			contentStatus: "",
			contentZoom: 0.9,
			contentPointEvent: "none",
			contentOpacity: 0,
			contentTrans: 0.5,
			contentTiming: "",
			targetEl: null,
		};
	},
	computed: {},
	watch: {},
	methods: {
		setWindowShow() {
			this.frameStatus = "open";
			this.frameZoom = 1.05;
			this.frameOpacity = 1;
			this.frameTrans = 0.25;
			this.frameTiming = "ease-in";
			setTimeout(() => {
				this.frameZoom = 1.0;
				this.frameOpacity = 1;
				this.frameTrans = 0.25;
				this.frameTiming = "ease-out";
			}, 250);
			setTimeout(() => {
				this.setContentShow();
			}, 150);
		},
		setWindowShowFromMenu() {
			this.frameStatus = "open";
			this.frameTrans = 0;
			this.frameOpacity = 0;
			this.frameZoom = 1.0;
			this.frameTiming = "ease-in";
		},
		setWindowShowForMenuAfter() {
			this.frameOpacity = 1;
			this.frameTrans = 0;
			this.frameZoom = 1.0;
		},
		setWindowHiddenFromMenu() {
			this.frameTrans = 0;
			this.frameStatus = "";
			this.frameOpacity = 0;
			this.frameZoom = 0.9;
			this.frameTiming = "ease-in";
		},
		setContentShow(useBounce) {
			if (useBounce == null) useBounce = true;
			if (useBounce) {
				this.contentStatus = "open";
				this.contentZoom = 1.05;
				this.contentOpacity = 1;
				this.contentTrans = 0.25;
				this.contentTiming = "ease-in";
			} else {
				this.contentTrans = 0;
				this.contentZoom = 1;
			}
			setTimeout(
				() => {
					this.contentZoom = 1.0;
					this.contentOpacity = 1;
					this.contentTrans = useBounce ? 0.25 : 0.45;
					this.contentTiming = "ease-out";
				},
				useBounce ? 250 : 10
			);
		},
		setWindowHidden() {
			this.setContentHidden();
			setTimeout(() => {
				this.frameZoom = 1.05;
				this.frameOpacity = 1;
				this.frameTrans = 0.15;
				this.frameTiming = "ease-in";
				setTimeout(() => {
					this.frameZoom = 0.9;
					this.frameOpacity = 0;
					this.frameTrans = 0.15;
					this.frameTiming = "ease-out";
				}, 150);
			}, 150);
			setTimeout(() => {
				this.contentStatus = "close";
			}, 300);
		},
		setContentHidden(useBounce) {
			if (useBounce == null) useBounce = true;
			if (useBounce) {
				this.contentZoom = 1.05;
				this.contentOpacity = 1;
				this.contentTrans = 0.15;
				this.contentTiming = "ease-in";
			}
			setTimeout(
				() => {
					this.contentZoom = useBounce ? 0.9 : 1;
					this.contentOpacity = 0;
					this.contentTrans = useBounce ? 0.15 : 0.25;
					this.contentTiming = "ease-out";
				},
				useBounce ? 150 : 0
			);
			this.contentStatus = "close";
		},
		setWindowAuto() {
			var vm = this;
			if (vm.targetEl == null) {
				return;
			}
			vm.setWindowSize(vm.targetEl, false, false);
		},
		setWindowSize(input, useAnime, useBounce) {
			this.targetEl = input;
			let targetHeight = input.$el.clientHeight;
			let targetWidth = input.$el.clientWidth;
			if (useBounce == null) useBounce = true;
			this.setWindowHeight(targetHeight, useAnime, useBounce);
			this.setWindowWidth(targetWidth, useAnime, useBounce);
		},
		setWindowSizeByNum(targetWidth, targetHeight, useAnime, useBounce) {
			if (useBounce == null) useBounce = true;
			this.setWindowHeight(targetHeight, useAnime);
			this.setWindowWidth(targetWidth, useAnime);
		},
		setWindowHeight(target, useAnime, useBounce) {
			let nowHeight = this.baseHeight;
			let value = target - nowHeight;
			if (useBounce) {
				let newtarget = target + (value / 5) * 1;
				if (newtarget <= 0) newtarget = (target / 5) * 4;
				if (newtarget == nowHeight) newtarget = target * 1.05;
				this.baseHeight = newtarget;
				if (useAnime) this.baseTrans = 0.35;
				else this.baseTrans = 0;
				this.baseTiming = "ease-in";
			}
			setTimeout(
				() => {
					this.baseHeight = target;
					if (useAnime) this.baseTrans = 0.25;
					else this.baseTrans = 0;
					this.baseTiming = "ease-out";
				},
				useAnime && useBounce ? 350 : 0
			);
		},
		setWindowWidth(target, useAnime, useBounce) {
			let nowWidth = this.baseWidth;
			let value = target - nowWidth;
			if (useBounce) {
				let newtarget = target + (value / 5) * 1;
				if (newtarget <= 0) newtarget = (target / 5) * 4;
				if (newtarget == nowWidth) newtarget = target * 1.05;
				this.baseWidth = newtarget;
				if (useAnime) this.baseTrans = 0.35;
				else this.baseTrans = 0;
				this.baseTiming = "ease-in";
			}
			setTimeout(
				() => {
					this.baseWidth = target;
					if (useAnime) this.baseTrans = 0.25;
					else this.baseTrans = 0;
					this.baseTiming = "ease-out";
				},
				useAnime && useBounce ? 350 : 0
			);
		},
		setContentSwitch(contentList, target) {
			var vm = this;
			vm.setContentHidden();
			setTimeout(() => {
				contentList.forEach(function (content) {
					content.hideContent();
				});
				vm.setWindowSize(target, true);
				setTimeout(() => {
					target.showContent();
					vm.setContentShow();
				}, 250);
			}, 250);
		},
	},
	created() {
		window.addEventListener("resize", this.setWindowAuto);
	},
	destroyed() {
		window.removeEventListener("resize", this.setWindowAuto);
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./WindowFrame.css">
</style>