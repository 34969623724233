<template>
	<div class="layoutWrapper" ref="frame">
		<div class="largewrapper">
			<div class="row">
				<div class="col-12">
					<div class="iconwrapper">
						<GlobalIcon
							:size="largeSize"
							ref="largeicon"
							:class="[largeStatus]"
						/>
					</div>
				</div>
				<div class="col-12 mt-4 text-center largetext" :class="[largetext]">
					<h1><strong>Cash Tracert</strong></h1>
				</div>
			</div>
		</div>
		<div class="smallwrapper">
			<div class="barbg" :class="[barbgStatus]"></div>
			<div class="row">
				<div class="col-12">
					<div class="titlewrapper">
						<GlobalIcon
							:size="smallSize"
							ref="smallicon"
							:class="[smallStatus]"
						/>
						<div class="pt-1 ms-2 smalltext" :class="[smalltext]">
							<h5 class="m-0"><strong>Cash Tracert</strong></h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="movewrapper"
			:style="{
				opacity: baseOpacity,
				top: baseTop + 'px',
				left: baseLeft + 'px',
				pointerEvents: basePointEvent,
				transition: baseTrans + 's',
				transitionTimingFunction: baseTiming,
			}"
		>
			<GlobalIcon :size="baseSize" />
		</div>
	</div>
</template>

<script>
import GlobalIcon from "@/components/Global/GlobalIcon.vue";
export default {
	name: "GlobalIconTrans",
	props: ["status", "largeSize", "smallSize"],
	data() {
		return {
			baseTop: 0,
			baseLeft: 0,
			basePointEvent: "none",
			baseOpacity: 0,
			baseTrans: 0,
			baseTiming: "",
			baseSize: 192,
			largeStatus: "",
			smallStatus: "iconhide",
			largetext: "textshow",
			smalltext: "texthide",
			barbgStatus: "hide",
			baseStatus: "large",
		};
	},
	computed: {},
	components: {
		GlobalIcon,
	},
	watch: {},
	methods: {
		setIconToBar() {
			var vm = this;
			if (vm.baseStatus == "small") return;
			vm.baseStatus = "small";
			let frameRect = vm.$refs.frame.getBoundingClientRect();
			let sourceRect = vm.$refs.largeicon.$el.getBoundingClientRect();
			vm.baseTop = sourceRect.top - frameRect.top;
			vm.baseLeft = sourceRect.left - frameRect.left;
			vm.baseSize = vm.largeSize;
			vm.baseOpacity = 1;
			vm.largeStatus = "iconhide";
			vm.largetext = "texthide";
			vm.barbgStatus = "show";

			setTimeout(() => {
				vm.baseTrans = 0.45;
				let targetRect = vm.$refs.smallicon.$el.getBoundingClientRect();
				vm.baseTop = targetRect.top - frameRect.top;
				vm.baseLeft = targetRect.left - frameRect.left;
				vm.baseSize = vm.smallSize;
				setTimeout(() => {
					vm.baseTrans = 0;
					vm.baseOpacity = 0;
					if (vm.baseStatus == "large") {
						vm.largeStatus = "";
					}
					if (vm.baseStatus == "small") {
						vm.smallStatus = "";
						vm.smalltext = "textshow";
					}
				}, 450);
			}, 50);
		},
		setBarToIcon() {
			var vm = this;
			if (vm.baseStatus == "large") return;
			vm.baseStatus = "large";
			let frameRect = vm.$refs.frame.getBoundingClientRect();
			let sourceRect = vm.$refs.smallicon.$el.getBoundingClientRect();
			vm.baseTop = sourceRect.top - frameRect.top;
			vm.baseLeft = sourceRect.left - frameRect.left;
			vm.baseSize = vm.smallSize;
			vm.baseOpacity = 1;
			vm.smallStatus = "iconhide";
			vm.smalltext = "texthide";
			vm.barbgStatus = "hide";

			setTimeout(() => {
				vm.baseTrans = 0.45;
				let targetRect = vm.$refs.largeicon.$el.getBoundingClientRect();
				vm.baseTop = targetRect.top - frameRect.top;
				vm.baseLeft = targetRect.left - frameRect.left;
				vm.baseSize = vm.largeSize;
				vm.largetext = "textshow";
				setTimeout(() => {
					vm.baseTrans = 0;
					vm.baseOpacity = 0;
					if (vm.baseStatus == "large") {
						vm.largeStatus = "";
					}
					if (vm.baseStatus == "small") {
						vm.smallStatus = "";
						vm.smalltext = "textshow";
					}
				}, 450);
			}, 50);
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		setTimeout(() => {
			vm.baseSize = vm.largeSize;
		}, 200);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layoutWrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	pointer-events: none;
}
.largewrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 32px 16px;
}
.smallwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding-top: 4px;
	padding-bottom: 4px;
}
.largetext.textshow {
	opacity: 1;
	animation: largetext-show 0.65s linear;
}
@keyframes largetext-show {
	0% {
		opacity: 0;
		transform: translate(0, 0);
	}
	75% {
		opacity: 0;
		transform: translate(0, 0);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}
.largetext.texthide {
	opacity: 0;
	animation: largetext-hide 0.2s linear;
}
@keyframes largetext-hide {
	0% {
		opacity: 1;
		transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, -120px);
	}
}

.smalltext.textshow {
	opacity: 1;
	animation: smalltext-show 0.35s linear;
}
@keyframes smalltext-show {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.smalltext.texthide {
	opacity: 0;
	animation: smalltext-hide 0.35s linear;
}
@keyframes smalltext-hide {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.barbg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--bg-rgba-bg-15);
	z-index: -1;
	transition: 0.25s;
}
.barbg.show {
	opacity: 1;
	animation: barbg-show 0.35s ease-in-out;
}
@keyframes barbg-show {
	0% {
		opacity: 0;
		transform: translate(0, -48px);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}
.barbg.hide {
	opacity: 0;
	animation: barbg-hide 0.35s ease-in-out;
}
@keyframes barbg-hide {
	0% {
		opacity: 1;
		transform: translate(0, 0);
	}
	100% {
		opacity: 1;
		transform: translate(0, -48px);
	}
}
.iconwrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.titlewrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.movewrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.iconhide {
	opacity: 0;
	pointer-events: none;
}
</style>
