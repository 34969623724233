import { render, staticRenderFns } from "./ExpensesMenu.html?vue&type=template&id=0b663321&scoped=true&"
import script from "./ExpensesMenu.vue?vue&type=script&lang=js&"
export * from "./ExpensesMenu.vue?vue&type=script&lang=js&"
import style0 from "./ExpenseMenu.css?vue&type=style&index=0&id=0b663321&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b663321",
  null
  
)

export default component.exports