<template>
	<div>
		<div class="progresswrapper">
			<div class="progress" :class="[status]">
				<div class="progressborder"></div>
				<div class="progresswait">
					<div class="progresswaitbg"></div>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import store from "@/store";
export default {
	name: "ConnectSpin",
	props: {},
	data() {
		return {
			status: "",
		};
	},
	computed: {
		isShow: {
			get() {
				let target = store.state.rootStore.isLoading;
				return target;
			},
		},
	},
	watch: {
		isShow(newValue) {
			if (newValue) {
				this.status = "show";
			} else {
				this.status = "hide";
			}
		},
	},
	methods: {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.progresswrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: 15vh;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	z-index: 995;
}
.progress {
	position: relative;
	height: 8px;
	width: 192px;
	opacity: 0;
	border-radius: 12px;
	background: var(--bg-rgba-bg-25);
	backdrop-filter: blur(24px);
	box-shadow: var(--shadow-progress-150);
	transition: 0.5s;
}
.progress.show {
	opacity: 1;
	animation: progress-fadein 0.25s;
}
@keyframes progress-fadein {
	0% {
		opacity: 0;
		transform: translate(0, 16px);
	}
	35% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}
.progress.hide {
	opacity: 0;
	animation: progress-fadeout 0.75s;
}
@keyframes progress-fadeout {
	0% {
		opacity: 1;
		transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 16px);
	}
}
.progressborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 2px solid rgba(100, 100, 100, 0.35);
	border-radius: 12px;
}
.progresswait {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
}
.progresswaitbg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50%;
	filter: blur(8px);
	background: rgb(255, 255, 255);
	background: linear-gradient(
		90deg,
		var(--bg-rgba-bg-0) 0%,
		rgba(0, 0, 255, 0.5) 40%,
		rgba(0, 0, 255, 1) 80%,
		rgba(0, 0, 255, 1) 90%,
		var(--bg-rgba-bg-0) 100%
	);
	animation: progresswaitbg-spin 1.5s ease-in-out infinite;
}
@keyframes progresswaitbg-spin {
	0% {
		transform: translateX(-200%);
	}
	100% {
		transform: translateX(200%);
	}
}
</style>