import SHA256 from "crypto-js/sha256";
import MD5 from "crypto-js/md5";
import Base64 from "crypto-js/enc-base64";
import UTF8 from "crypto-js/enc-utf8";
import SHA1 from "crypto-js/sha1";
import AES from "crypto-js/aes";

export default {
	hashSha1: hashSha1,
	hashSha256: hashSha256,
	hashMd5: hashMd5,
	encodeBase64: encodeBase64,
	decodeBase64: decodeBase64,
	encryptAES: encryptAES,
	decryptAES: decryptAES,
};

async function hashSha1(input) {
	return SHA1(input).toString();
}
async function hashSha256(input) {
	return SHA256(input).toString();
}

async function hashMd5(input) {
	return MD5(input).toString();
}
async function encodeBase64(input) {
	return Base64.stringify(UTF8.parse(input));
}
async function decodeBase64(input) {
	let result = "";
	try {
		result = UTF8.stringify(Base64.parse(input));
	} catch (error) {
		result = "Can't Decode From Base64";
	}
	return result;
}
async function encryptAES(input, key) {
	return AES.encrypt(input, key).toString();
}
async function decryptAES(input, key) {
	const bytes = AES.decrypt(input, key);
	return bytes.toString(UTF8);
}
