<template src="./NotifyList.html"></template>

<script>
import store from "@/store";
import NotifyItem from "../NotifyItem/NotifyItem.vue";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "NotifyList",
	props: {},
	data() {
		return {};
	},
	components: {
		NotifyItem,
	},
	computed: {
		notifyList: {
			get() {
				return store.state.msgStore.notifyList;
			},
		},
		notifyCount: {
			get() {
				return store.state.msgStore.notifyList.length;
			},
		},
	},
	watch: {},
	methods: {
		removeNotify(obj) {
			msgCtrl.removeNotify(obj);
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./NotifyList.css">
</style>
