<template>
	<div>
		<div class="debugWrapper" :class="[status]">
			<div class="row text-wordbread">
				<div class="col-12">Url Query</div>
				<div class="col-12">
					{{ urlQuery }}
				</div>
				<div class="col-12">Debug Data</div>
				<div class="col-12">
					{{ debugData }}
				</div>
			</div>
			<div class="debugFunction">
				<div class="row">
					<div class="col-12">
						<button class="btn btn-outline-light" @click="goToView('test')">
							Test View
						</button>
						<button class="btn btn-outline-light" @click="goToView('home')">
							Home View
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="debugBtn" @click="changeStatus">
			<i class="fa-solid fa-terminal"></i>
		</div>
	</div>
</template>
  
<script>
import router from "@/router";
import store from "@/store";
export default {
	name: "DebugOverlay",
	props: {},
	data() {
		return {
			status: "hide",
			urlQuery: "",
			debugData: "",
		};
	},
	computed: {},
	watch: {},
	methods: {
		changeStatus() {
			var vm = this;
			if (vm.status == "hide") vm.status = "show";
			else vm.status = "hide";
		},
		getURLQuery() {
			return JSON.stringify(router.currentRoute.query, null, "\t");
		},
		getDebugData() {
			return JSON.stringify(store.state.debugData, null, "\t");
		},
		goToView(name) {
			router.push({ name: name });
		},
	},
	mounted() {
		var vm = this;
		setInterval(() => {
			vm.urlQuery = vm.getURLQuery();
			vm.debugData = vm.getDebugData();
		}, 100);
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.debugWrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 16px;
	padding-top: env(safe-area-inset-top);
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.35);
	color: white;
	z-index: 997;
	pointer-events: none;
}
.debugWrapper.show {
	display: block;
}
.debugWrapper.hide {
	display: none;
}
.debugFunction {
	position: absolute;
	left: 0;
	right: 0;
	bottom: env(safe-area-inset-bottom);
	background: rgba(0, 0, 0, 0.15);
	pointer-events: all;
}
.debugBtn {
	position: fixed;
	top: 70%;
	left: 0;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	background: rgba(0, 0, 0, 0.25);
	border: solid 1px #888;
	color: white;
	z-index: 999;
}
</style>