var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"layoutOverlay",class:[_vm.overlayStatus],style:({ bottom: _vm.wrapperHeight + 'px' }),on:{"click":_vm.onSubBack}}),_c('div',{staticClass:"layoutWrapper",class:[_vm.showStatus],style:({ height: _vm.wrapperHeight + 'px' })},[_c('div',{staticClass:"layoutContent"},[_c('div',{staticClass:"layoutBg",class:{ disableBlur: _vm.isDisableBlur }}),_c('div',{ref:"layoutMenu",staticClass:"layoutMenu",class:[_vm.mainMenuStatus]},[_c('div',{staticClass:"menuItem",class:{ active: _vm.isMenuItemActive('Overview') },attrs:{"data-name":"Overview"},on:{"click":function($event){return _vm.onClickMenuItem('Overview')}}},[_vm._m(0)]),_c('div',{staticClass:"menuItem",class:{ active: _vm.isMenuItemActive('Expenses') },attrs:{"data-name":"Expenses"},on:{"click":function($event){return _vm.onClickMenuItem('Expenses')}}},[_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"menuItem",class:{ active: _vm.isMenuItemActive('Income') },attrs:{"data-name":"Income"},on:{"click":function($event){return _vm.onClickMenuItem('Income')}}},[_vm._m(3)]),_c('div',{staticClass:"menuItem",class:{ active: _vm.isMenuItemActive('Setting') },attrs:{"data-name":"Setting"},on:{"click":function($event){return _vm.onClickMenuItem('Setting')}}},[_vm._m(4)]),_c('div',{staticClass:"menuItemActive",style:({
							height: _vm.menuActiveData.height + 'px',
							width: _vm.menuActiveData.width + 'px',
							transform:
								'translate(' +
								_vm.menuActiveData.left +
								'px' +
								',' +
								_vm.menuActiveData.top +
								'px' +
								')',
						})},[_c('div',{staticClass:"menuItemActive-content"})])]),_c('div',{staticClass:"layoutSubMenu",class:[_vm.subMenuStatus]},[_c('div',{staticClass:"layoutSub-top"},[_c('div',{staticClass:"layoutSub-top-title"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.targetSubMenuName == 'Overview'),expression:"targetSubMenuName == 'Overview'"}]},[_c('i',{staticClass:"fa-solid fa-house"}),_c('strong',[_vm._v("總覽")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.targetSubMenuName == 'Expenses'),expression:"targetSubMenuName == 'Expenses'"}]},[_c('i',{staticClass:"fa-solid fa-receipt"}),_c('strong',[_vm._v("支出")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.targetSubMenuName == 'Income'),expression:"targetSubMenuName == 'Income'"}]},[_c('i',{staticClass:"fa-solid fa-piggy-bank"}),_c('strong',[_vm._v("收入")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.targetSubMenuName == 'Setting'),expression:"targetSubMenuName == 'Setting'"}]},[_c('i',{staticClass:"fa-solid fa-gear"}),_c('strong',[_vm._v("設定")])])]),_c('div',{staticClass:"layoutSub-top-backbtn"},[_c('button',{staticClass:"btn btn-circle btn-back",on:{"click":_vm.onSubBack}},[_c('i',{staticClass:"fa-solid fa-arrow-left"})])])]),_c('div',{ref:"layoutSubContent",staticClass:"layoutSub-content"},[_c('div',{staticClass:"layoutSub-content-menu",class:{ show: _vm.targetSubMenuName == 'Overview' }},[_c('OverviewMenu')],1),_c('div',{staticClass:"layoutSub-content-menu",class:{ show: _vm.targetSubMenuName == 'Expenses' }},[_c('ExpensesMenu')],1),_c('div',{staticClass:"layoutSub-content-menu",class:{ show: _vm.targetSubMenuName == 'Income' }},[_c('IncomeMenu')],1),_c('div',{staticClass:"layoutSub-content-menu",class:{ show: _vm.targetSubMenuName == 'Setting' }},[_c('SettingMenu')],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuItem-content"},[_c('div',{staticClass:"menuItem-icon"},[_c('i',{staticClass:"fa-solid fa-house"})]),_c('div',{staticClass:"menuItem-text"},[_vm._v("總覽")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuItem-content"},[_c('div',{staticClass:"menuItem-icon"},[_c('i',{staticClass:"fa-solid fa-receipt"})]),_c('div',{staticClass:"menuItem-text"},[_vm._v("支出")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuItem main"},[_c('div',{staticClass:"menuItem-content"},[_c('div',{staticClass:"menuItem-icon"},[_c('i',{staticClass:"fa-solid fa-plus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuItem-content"},[_c('div',{staticClass:"menuItem-icon"},[_c('i',{staticClass:"fa-solid fa-piggy-bank"})]),_c('div',{staticClass:"menuItem-text"},[_vm._v("收入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuItem-content"},[_c('div',{staticClass:"menuItem-icon"},[_c('i',{staticClass:"fa-solid fa-gear"})]),_c('div',{staticClass:"menuItem-text"},[_vm._v("設定")])])
}]

export { render, staticRenderFns }