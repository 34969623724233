import client from "@/api/client";

export default {
	incomeFixed: {
		checkParameter: (data) => {
			return client({
				url: "api/Income/CheckParameter",
				method: "post",
				data,
			});
		},
		reviewParameter: (data) => {
			return client({
				url: "api/Income/ReviewParameter",
				method: "post",
				data,
			});
		},
		setEntity: (data) => {
			return client({
				url: "api/Income/SetFixedEntity",
				method: "post",
				data,
			});
		},
		delEntity: (data) => {
			return client({
				url: "api/Income/DelFixedEntity",
				method: "post",
				data,
			});
		},
		delEntityList: (data) => {
			return client({
				url: "api/Income/DelFixedEntityList",
				method: "post",
				data,
			});
		},
		getDataList: (data) => {
			return client({
				url: "api/Income/GetFixedDataList",
				method: "post",
				data,
			});
		},
	},
};
