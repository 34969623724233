var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalwrapper",class:[_vm.wrapperStatus]},[_c('div',{staticClass:"modalwrapperbg",class:[_vm.wrapperBgStatus],on:{"click":_vm.onWrapperClick}}),_c('div',{staticClass:"modalmain",class:[_vm.modalStatus, _vm.width]},[_c('div',{staticClass:"modalbg",class:{ 'disableBlur-blurOnly': _vm.isDisableBlur },style:({
					opacity: _vm.modalOpacity,
					transform:
						'translate(' +
						_vm.modalMoveX +
						'px, ' +
						_vm.modalMoveY +
						'px) scale(' +
						_vm.modalZoom +
						', ' +
						_vm.modalZoom +
						')',
					pointerEvents: _vm.modalPointEvent,
					transition: _vm.modalTrans + 's',
					transitionTimingFunction: _vm.modalTiming,
				})}),_c('div',{ref:"modalfocus",staticClass:"modalcontentwrapper",style:({
					opacity: _vm.contentOpacity,
					transform:
						'translate(' +
						_vm.contentMoveX +
						'px, ' +
						_vm.contentMoveY +
						'px) scale(' +
						_vm.contentZoom +
						', ' +
						_vm.contentZoom +
						')',
					pointerEvents: _vm.contentPointEvent,
					transition: _vm.contentTrans + 's',
					transitionTimingFunction: _vm.contentTiming,
				})},[_c('div',{staticClass:"modalcontent",class:[_vm.scroll, _vm.padding, _vm.height]},[_vm._t("content")],2),_c('div',{staticClass:"topbarslot",class:[_vm.padding, { 'disableBlur-blurOnly': _vm.isDisableBlur }]},[_vm._t("top")],2),_c('div',{staticClass:"bottombarslot",class:[_vm.padding, { 'disableBlur-blurOnly': _vm.isDisableBlur }]},[_vm._t("bar")],2),_c('div',{staticClass:"modelborder"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }