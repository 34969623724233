<template src="./FixedDetail.html"></template>

<script>
import moment from "moment";
import numberConvert from "@/utils/numberConvert";
import incomeRepo from "@/repos/incomeRepo";
import store from "@/store";
import modalCtrl from "@/utils/modalCtrl";
import msgCtrl from "@/utils/msgCtrl";
import SplitRight from "@/components/UI/FrameUI/SplitUI/SplitRight/SplitRight.vue";
import CalendarBox from "@/components/UI/UtililyUI/CalendarBox/CalendarBox.vue";
import BScroll from "@better-scroll/core";
import MouseWheel from "@better-scroll/mouse-wheel";
import PullDown from "@better-scroll/pull-down";
import ScrollBar from "@better-scroll/scroll-bar";
export default {
	name: "FixedDetail",
	props: {},
	data() {
		return {
			pageName: "Calendar",
			layoutContentStatus: "show",
			reviewEventList: [],
			targetItem: null,
			listScroll: null,
		};
	},
	components: {
		SplitRight,
		CalendarBox,
	},
	computed: {
		sourceItem: {
			get() {
				return store.state.incomeFixedItem;
			},
		},
		beforeEventList: {
			get() {
				var vm = this;
				let n = parseInt(moment().format("YYYYMMDD"));
				let data = vm.reviewEventList.filter((x) => {
					let d = parseInt(moment(x.start).format("YYYYMMDD"));
					return d < n;
				});
				return data;
			},
		},
		afterEventList: {
			get() {
				var vm = this;
				let n = parseInt(moment().format("YYYYMMDD"));
				let data = vm.reviewEventList.filter((x) => {
					let d = parseInt(moment(x.start).format("YYYYMMDD"));
					return d >= n;
				});
				return data;
			},
		},
	},
	watch: {
		sourceItem() {
			var vm = this;
			vm.resetData();
			setTimeout(() => {
				vm.targetItem = vm.sourceItem;
				vm.reviewData();
			}, 350);
		},
	},
	methods: {
		resetData() {
			var vm = this;
			if (vm.layoutContentStatus != "hide") vm.layoutContentStatus = "hide";
			if (vm.pageName != "Calendar") vm.pageChange();
			setTimeout(() => {
				vm.$refs.calendarBox.resetData();
				vm.layoutContentStatus = "show";
			}, 350);
		},
		pageChange() {
			var vm = this;
			vm.layoutContentStatus = "hide";
			setTimeout(() => {
				if (vm.pageName == "Calendar") {
					vm.pageName = "List";
					setTimeout(() => {
						vm.setScrollBar();
						vm.scrollToEvent(vm.$refs.eventList, vm.$refs.afterEvent);
					}, 250);
				} else {
					vm.scrollToEvent(vm.$refs.eventList, vm.$refs.beforeEvent);
					setTimeout(() => {
						vm.pageName = "Calendar";
						vm.calendarStatus = "hide";
					}, 20);
				}
				setTimeout(() => {
					vm.layoutContentStatus = "show";
				}, 50);
			}, 350);
		},
		showEditor() {
			var vm = this;
			if (vm.targetItem == null) return;
			modalCtrl.setModalBoxStatus(
				"IncomeFixedEditor",
				vm.targetItem,
				"Show",
				() => {
					incomeRepo.incomeFixed.getDataList();
				}
			);
		},
		createDescString() {
			var vm = this;
			if (vm.targetItem == null) return "尚未選擇選項，將在選擇之後顯示";

			let result =
				"從 " +
				moment(vm.targetItem.StartDate).format("YYYY年MM月DD日 開始<br/>") +
				"於";

			let week = "";
			if (vm.targetItem.WeekType == "Monday") week = "週一";
			if (vm.targetItem.WeekType == "Tuesday") week = "週二";
			if (vm.targetItem.WeekType == "Wednesday") week = "週三";
			if (vm.targetItem.WeekType == "Thursday") week = "週四";
			if (vm.targetItem.WeekType == "Friday") week = "週五";
			if (vm.targetItem.WeekType == "Saturday") week = "週六";
			if (vm.targetItem.WeekType == "Sunday") week = "週日";

			if (vm.targetItem.CalType == "Year") result += "<span>每年</span>";
			if (vm.targetItem.CalType == "Quarter") result += "<span>每季</span>";
			if (vm.targetItem.CalType == "Month") result += "<span>每月</span>";
			if (vm.targetItem.CalType == "Week")
				result += "<span>每個" + week + "</span>";

			if (vm.targetItem.YearType == "Quarter")
				result +=
					"<span>第" +
					numberConvert.getChinesNum(vm.targetItem.YearValue1) +
					"季</span>";
			if (vm.targetItem.YearType == "Month")
				result +=
					"<span>" +
					numberConvert.getChinesNum(vm.targetItem.YearValue1) +
					"月</span>";
			if (vm.targetItem.YearType == "Week")
				result +=
					"<span>第" +
					numberConvert.getChinesNum(vm.targetItem.YearValue1) +
					"週</span>";

			if (vm.targetItem.QuarterType == "FirstMonth")
				result += "的<span>第一個月</span>的";
			if (vm.targetItem.QuarterType == "SecondMonth")
				result += "的<span>每二個月</span>的";
			if (vm.targetItem.QuarterType == "ThirdMonth")
				result += "的<span>最後一個月</span>的";

			if (vm.targetItem.MonthType == "StartWeekOfMonth")
				result += "<span>第一個" + week + "</span>";
			if (vm.targetItem.MonthType == "EndWeekOfMonth")
				result += "<span>最後一個" + week + "</span>";
			if (vm.targetItem.MonthType == "WeekOfMonth")
				result +=
					"的<span>第" +
					numberConvert.getChinesNum(vm.targetItem.MonthValue1) +
					"週</span>的<span>" +
					week +
					"</span>";
			if (vm.targetItem.MonthType == "StartDayOfMonth")
				result += "的<span>第一天</span>";
			if (vm.targetItem.MonthType == "EndDayOfMonth")
				result += "的<span>最後一天</span>";
			if (vm.targetItem.MonthType == "DayOfMonth")
				result += "的<span>" + vm.targetItem.MonthValue1 + "號</span>";

			result +=
				"收到金額 " +
				numberConvert.numberToCurrencyNo(vm.targetItem.Amount) +
				" 的收入";
			return result;
		},
		setCalendarEvent(input) {
			var vm = this;
			vm.$refs.calendarBox.setCalendarEvent(input);
		},
		reviewData() {
			var vm = this;
			incomeRepo.incomeFixed.getParameterReview(vm.targetItem, function (data) {
				vm.reviewEventList = data;
				vm.setCalendarEvent(data);
			});
		},
		checkEvent(input, index) {
			var vm = this;
			let result = {
				status: false,
				class: "",
				title: "",
			};
			if (vm.isEventMonth(input))
				result = {
					status: true,
					class: "monthItem",
					title: "本月到來",
				};
			if (vm.isEventToday(input))
				result = {
					status: true,
					class: "todayItem",
					title: "今天",
				};
			if (!result.status && index == 0)
				result = {
					status: true,
					class: "nextItem",
					title: "即將到來",
				};

			return result;
		},
		isEventToday(input) {
			let n = parseInt(moment().format("YYYYMMDD"));
			let d = parseInt(moment(input.start).format("YYYYMMDD"));
			let result = false;
			if (n == d) result = true;
			return result;
		},
		isEventMonth(input) {
			let n = parseInt(moment().format("YYYYMM"));
			let d = parseInt(moment(input.start).format("YYYYMM"));
			let result = false;
			if (n == d) result = true;
			return result;
		},
		setScrollBar() {
			var vm = this;
			if (vm.listScroll != null) vm.listScroll.destroy();
			let target = vm.$refs.listScrollElement;
			if (target == null) return;
			BScroll.use(PullDown);
			BScroll.use(MouseWheel);
			BScroll.use(ScrollBar);
			vm.listScroll = new BScroll(target, {
				scrollY: true,
				scrollbar: true,
				click: true,
				bounce: {
					top: true,
					bottom: true,
				},
				mouseWheel: {
					speed: 20,
					invert: false,
					easeTime: 300,
				},
			});
		},
		scrollToEvent(body, ele) {
			var vm = this;
			if (vm.listScroll != null)
				vm.listScroll.scrollToElement(ele, 350, 0, -60, "ease-in");
		},
		delTargetItem() {
			var vm = this;

			msgCtrl.showMsgBoxYesNo(
				msgCtrl.iconList.trash,
				"移除固定收入",
				"確定要移除固定收入「" + vm.targetItem.Name + "」？",
				"確認移除",
				msgCtrl.buttonList.danger,
				"取消",
				msgCtrl.buttonList.light,
				() => {
					incomeRepo.incomeFixed.delEntity(vm.targetItem);
				},
				() => {}
			);
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		setTimeout(() => {
			vm.setScrollBar();
		}, 50);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FixedDetail.css">
</style>
