import Vue from "vue";
import Vuex from "vuex";
import msgStore from "@/store/msgStore";
import rootStore from "./rootStore";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		//Debug
		debugData: {},
		//Frame 切換
		frameName: "",
		framePageList: [],
		frameContent: null,
		//Tab 切換
		tabTargetName: "",
		//ModalBox
		modalBoxList: [],
		modalBoxInput: null,
		//Income 物件
		incomeData: {
			incomeFixedList: [],
		},
		//IncomeFixed 選擇物件
		incomeFixedItem: null,
		//Store 物件
		storeData: {
			storeList: [],
			expensesList: [],
			expensesMethodList: [],
		},
	},
	getters: {
		isModalBoxOpen(state) {
			let result = false;
			let data = state.modalBoxList.filter((x) => {
				return x.modal.isModalOpen;
			});
			if (data.length > 0) result = true;
			return result;
		},
		framePageShow(state) {
			let result = null;
			let data = state.framePageList.filter((x) => {
				return x.page.showPage;
			});
			if (data.length > 0) result = data[0];
			return result;
		},
		isframePageRight(state) {
			let result = false;
			let data = state.framePageList.filter((x) => {
				return x.page.showPage;
			});

			if (data.length > 0) {
				data = data[0];
				if (data.page.layoutSplit == "Right") result = true;
			}
			return result;
		},
	},
	mutations: {
		setFrameName(state, v) {
			state.frameName = v;
		},
		setTabTargetName(state, v) {
			state.tabTargetName = v;
		},
		pushFramePage(state, v) {
			let item = state.framePageList.filter((x) => {
				return x.name == v.name;
			});
			if (item == null || item.length == 0) {
				state.framePageList.push(v);
			} else {
				item[0].page = v.page;
				item[0].title = v.title;
			}
		},
		setFrameContent(state, v) {
			state.frameContent = v;
		},
		pushModalBox(state, v) {
			let item = state.modalBoxList.filter((x) => {
				return x.name == v.name;
			});
			if (item == null || item.length == 0) {
				state.modalBoxList.push(v);
			} else {
				item[0].content = v.content;
				item[0].modal = v.modal;
			}
		},
		setModalBoxInput(state, v) {
			state.modalBoxInput = v;
		},
		setIncomeFixedList(state, v) {
			state.incomeData.incomeFixedList = v;
		},
		setIncomeFixedItem(state, v) {
			state.incomeFixedItem = v;
		},
		setStoreStoreList(state, v) {
			state.storeData.storeList = v;
		},
		setStoreExpensesList(state, v) {
			state.storeData.expensesList = v;
		},
		setStoreExpensesMethodList(state, v) {
			state.storeData.expensesMethodList = v;
		},
	},
	actions: {
		frameChange(context, input) {
			context.commit("setFrameName", input);
		},
		tabChange(context, input) {
			context.commit("setTabTargetName", input);
		},
		framePageAdd(context, input) {
			context.commit("pushFramePage", input);
		},
		frameContent(context, input) {
			context.commit("setFrameContent", input);
		},
		modalBoxAdd(context, input) {
			context.commit("pushModalBox", input);
		},
		setModalBoxInput(context, input) {
			context.commit("setModalBoxInput", input);
		},
		setIncomeFixedList(context, input) {
			context.commit("setIncomeFixedList", input);
		},
		setIncomeFixedItem(context, input) {
			context.commit("setIncomeFixedItem", input);
		},
	},
	modules: {
		rootStore: rootStore,
		msgStore: msgStore,
	},
});
