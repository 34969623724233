<template src="./FixedEditor.html"></template>

<script>
import _ from "lodash";
import moment from "moment";
import store from "@/store";
import ErrorToolTip from "@/components/Global/ErrorToolTip.vue";
import ModalBox from "@/components/UI/WindowUI/ModalBox/ModalBox.vue";
import CalendarBox from "@/components/UI/UtililyUI/CalendarBox/CalendarBox.vue";
import msgCtrl from "@/utils/msgCtrl";
import incomeRepo from "@/repos/incomeRepo";
import modalCtrl from "@/utils/modalCtrl";
export default {
	name: "FixedEditor",
	props: {},
	data() {
		return {
			mode: "",
			errorResult: [],
			isChecked: false,
			doCheckEvent: null,
			oldData: null,
			checkedData: null,
			targetData: {
				Name: "",
				Amount: 0,
				StartDate: null,
				EndDate: null,

				CalType: "Month",

				YearType: "None",
				YearValue1: 0,
				YearValue2: 0,

				QuarterType: "None",
				QuarterValue1: 0,
				QuarterValue2: 0,

				MonthType: "EndWeekOfMonth",
				MonthValue1: 0,
				MonthValue2: 0,

				WeekType: "Friday",
				WeekValue1: 0,
				WeekValue2: 0,
			},
			emptyData: {
				Name: "",
				Amount: 0,
				StartDate: null,
				EndDate: null,

				CalType: "None",
				YearType: "None",
				YearValue1: 0,
				YearValue2: 0,

				QuarterType: "None",
				QuarterValue1: 0,
				QuarterValue2: 0,

				MonthType: "None",
				MonthValue1: 0,
				MonthValue2: 0,

				WeekType: "None",
				WeekValue1: 0,
				WeekValue2: 0,
			},
			yearValueMax: 0,
			yearValueMin: 0,
			yearValue1: 0,
			monthValueMax: 0,
			monthValueMin: 0,
			monthValue1: 0,
		};
	},
	components: {
		ModalBox,
		CalendarBox,
		ErrorToolTip,
	},
	computed: {
		userEntity: {
			get() {
				return store.state.rootStore.userEntity;
			},
		},
		startDate: {
			get() {
				var vm = this;
				return moment(vm.userEntity.StartDate.toString()).format("YYYY-MM-DD");
			},
		},
		isCanSave: {
			get() {
				var vm = this;
				let result = true;
				if (!vm.isChecked) result = false;
				if (vm.targetData.Name == null || vm.targetData.Name == "")
					result = false;
				if (vm.targetData.StartDate == null || vm.targetData.StartDate == "")
					result = false;
				if (
					vm.targetData.Amount == null ||
					vm.targetData.Amount == "" ||
					vm.targetData.Amount == 0
				)
					result = false;
				return result;
			},
		},
		startDateValue: {
			get() {
				var vm = this;
				if (vm.targetData.StartDate == null || vm.targetData.StartDate == "") {
					return null;
				}
				let result = moment(vm.targetData.StartDate).format("YYYY-MM-DD");
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.StartDate = value;
			},
		},
		endDateValue: {
			get() {
				var vm = this;
				if (vm.targetData.EndDate == null || vm.targetData.EndDate == "") {
					return null;
				}
				let result = moment(vm.targetData.EndDate).format("YYYY-MM-DD");
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.EndDate = value;
			},
		},
		calType: {
			get() {
				var vm = this;
				let result = null;
				vm.dropdownData.calType.forEach((item) => {
					if (item.value == vm.targetData.CalType) result = item;
				});
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.CalType = value.value;
			},
		},
		yearType: {
			get() {
				var vm = this;
				let result = null;
				vm.dropdownData.yearType.forEach((item) => {
					if (item.value == vm.targetData.YearType) result = item;
				});
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.YearType = value.value;
			},
		},
		quarterType: {
			get() {
				var vm = this;
				let result = null;
				vm.dropdownData.quarterType.forEach((item) => {
					if (item.value == vm.targetData.QuarterType) result = item;
				});
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.QuarterType = value.value;
			},
		},
		monthType: {
			get() {
				var vm = this;
				let result = null;
				vm.dropdownData.monthType.forEach((item) => {
					if (item.value == vm.targetData.MonthType) result = item;
				});
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.MonthType = value.value;
			},
		},
		weekType: {
			get() {
				var vm = this;
				let result = null;
				vm.dropdownData.weekType.forEach((item) => {
					if (item.value == vm.targetData.WeekType) result = item;
				});
				return result;
			},
			set(value) {
				var vm = this;
				vm.targetData.WeekType = value.value;
			},
		},
		isYearShow: {
			get() {
				var vm = this;
				let result = false;
				if (vm.targetData.CalType == "Year") result = true;
				if (!result) {
					vm.targetData.YearType = "None";
					vm.targetData.YearValue1 = 0;
					vm.targetData.YearValue2 = 0;
				}
				return result;
			},
		},
		yearTitle: {
			get() {
				var vm = this;
				let result = "該年份的哪個範圍";
				if (vm.targetData.CalType == "Year") result = "每年的哪個範圍";
				return result;
			},
		},
		isYearValue1Show: {
			get() {
				var vm = this;
				let result = false;
				if (vm.targetData.YearType == "Quarter") result = true;
				if (vm.targetData.YearType == "Month") result = true;
				if (vm.targetData.YearType == "Week") result = true;
				if (!result) {
					vm.targetData.YearValue1 = 0;
				}
				return result;
			},
		},
		yearValue1Title: {
			get() {
				var vm = this;
				let result = "未知";
				if (vm.targetData.YearType == "Quarter") result = "每年第幾季度";
				if (vm.targetData.YearType == "Month") result = "每年的哪個月";
				if (vm.targetData.YearType == "Week") result = "每年的第幾週";
				return result;
			},
		},
		isQuarterShow: {
			get() {
				var vm = this;
				let result = false;
				if (vm.targetData.CalType == "Quarter") result = true;
				if (vm.targetData.YearType == "Quarter") result = true;
				if (!result) {
					vm.targetData.QuarterType = "None";
					vm.targetData.QuarterValue1 = 0;
					vm.targetData.QuarterValue2 = 0;
				}
				return result;
			},
		},
		quarterTitle: {
			get() {
				var vm = this;
				let result = "該季度的哪個月份";
				if (vm.targetData.CalType == "Quarter") result = "每個季度的哪個月份";
				return result;
			},
		},
		isMonthShow: {
			get() {
				var vm = this;
				let result = false;
				if (vm.targetData.CalType == "Month") result = true;
				if (vm.targetData.YearType == "Month") result = true;
				if (vm.targetData.QuarterType != "None") result = true;
				if (!result) {
					vm.targetData.MonthType = "None";
					vm.targetData.MonthValue1 = 0;
					vm.targetData.MonthValue2 = 0;
				}
				return result;
			},
		},
		monthTitle: {
			get() {
				var vm = this;
				let result = "該月份的哪個範圍";
				if (vm.targetData.CalType == "Month") result = "每個月的哪個範圍";
				return result;
			},
		},
		isMonthValue1Show: {
			get() {
				var vm = this;
				let result = false;
				if (vm.targetData.MonthType == "WeekOfMonth") result = true;
				if (vm.targetData.MonthType == "DayOfMonth") result = true;
				if (!result) {
					vm.targetData.MonthValue1 = 0;
				}
				return result;
			},
		},
		monthValue1Title: {
			get() {
				var vm = this;
				let result = "未知";

				let start = "該月份";
				if (vm.targetData.CalType == "Month") start = "每個月";

				if (vm.targetData.MonthType == "WeekOfMonth")
					result = start + "的哪一週";
				if (vm.targetData.MonthType == "DayOfMonth")
					result = start + "的哪一天";

				return result;
			},
		},
		isWeekShow: {
			get() {
				var vm = this;
				let result = false;
				if (vm.targetData.CalType == "Week") result = true;
				if (vm.targetData.YearType == "Week") result = true;
				if (vm.targetData.MonthType == "StartWeekOfMonth") result = true;
				if (vm.targetData.MonthType == "EndWeekOfMonth") result = true;
				if (vm.targetData.MonthType == "WeekOfMonth") result = true;
				if (!result) {
					vm.targetData.WeekType = "None";
					vm.targetData.WeekValue1 = 0;
					vm.targetData.WeekValue2 = 0;
				}
				return result;
			},
		},
		weekTitle: {
			get() {
				var vm = this;
				let result = "該星期的哪一天";
				if (vm.targetData.CalType == "Week") result = "每個星期的哪一天";
				return result;
			},
		},
		dropdownData: {
			get() {
				return incomeRepo.incomeFixed.getDropdownTypeList();
			},
		},
	},
	watch: {
		targetData: {
			handler() {
				var vm = this;
				if (vm.doCheckEvent != null) vm.doCheckEvent();
			},
			deep: true,
		},
		"targetData.YearType"(newValue) {
			var vm = this;
			vm.yearValueMax = 0;
			vm.yearValueMin = 0;
			if (newValue == "Quarter") {
				vm.yearValueMax = 4;
				vm.yearValueMin = 1;
				if (vm.yearValue1 == 0) vm.yearValue1 = 1;
			}
			if (newValue == "Month") {
				vm.yearValueMax = 12;
				vm.yearValueMin = 1;
				if (vm.yearValue1 == 0) vm.yearValue1 = 1;
			}
			if (newValue == "Week") {
				vm.yearValueMax = 53;
				vm.yearValueMin = -1;
				if (vm.yearValue1 == 0) vm.yearValue1 = 1;
			}
		},
		yearValue1(newValue) {
			var vm = this;
			if (newValue == null || newValue == "") return;
			let result = parseInt(newValue);
			if (result < vm.yearValueMin) result = vm.yearValueMin;
			if (result > vm.yearValueMax) result = vm.yearValueMax;
			vm.targetData.YearValue1 = result;
			if (result.toString() != newValue) vm.yearValue1 = result;
		},
		"targetData.MonthType"(newValue) {
			var vm = this;
			vm.monthValueMax = 0;
			vm.monthValueMin = 0;
			if (newValue == "WeekOfMonth") {
				vm.monthValueMax = 5;
				vm.monthValueMin = 1;
				if (vm.monthValue1 == 0) vm.monthValue1 = 1;
			}
			if (newValue == "DayOfMonth") {
				vm.monthValueMax = 31;
				vm.monthValueMin = 1;
				if (vm.monthValue1 == 0) vm.monthValue1 = 1;
			}
		},
		monthValue1(newValue) {
			var vm = this;
			if (newValue == null || newValue == "") return;
			let result = parseInt(newValue);
			if (result < vm.monthValueMin) result = vm.monthValueMin;
			if (result > vm.monthValueMax) result = vm.monthValueMax;
			vm.targetData.MonthValue1 = result;
			if (result.toString() != newValue) vm.monthValue1 = result;
		},
	},
	methods: {
		showModal(input) {
			var vm = this;

			if (!input) vm.mode = "Add";
			else vm.mode = "Edit";

			vm.isChecked = false;
			vm.$refs.calendarBox.resetData();

			if (!input) input = JSON.parse(JSON.stringify(vm.emptyData));
			vm.targetData = JSON.parse(JSON.stringify(input));

			vm.yearValue1 = vm.targetData.YearValue1;
			vm.monthValue1 = vm.targetData.MonthValue1;

			vm.oldData = JSON.parse(JSON.stringify(vm.targetData));

			setTimeout(() => {
				if (vm.mode == "Edit") vm.reviewData();
			}, 50);
			setTimeout(() => {
				vm.$refs.modal.showModal();
			}, 150);
		},
		doHideModal() {
			var vm = this;
			modalCtrl.setModalBox(vm.$refs.modal.Name, "Hide");
		},
		hideModal() {
			var vm = this;
			if (JSON.stringify(vm.oldData) === JSON.stringify(vm.targetData)) {
				vm.$refs.modal.hideModal();
				vm.calendarStatus = "hide";
				return;
			}

			msgCtrl.showMsgBoxYesNo(
				msgCtrl.iconList.quest,
				"尚未儲存",
				"目前畫面上的資料尚未儲存，確認要離開此界面？",
				"返回繼續編輯",
				msgCtrl.buttonList.light,
				"不儲存並離開",
				msgCtrl.buttonList.danger,
				() => {
					modalCtrl.setModalBox(vm.$refs.modal.Name, "Show");
				},
				() => {
					vm.$refs.modal.hideModal();
					vm.calendarStatus = "hide";
				}
			);
		},
		setCalendarEvent(input) {
			var vm = this;
			vm.$refs.calendarBox.setCalendarEvent(input);
		},
		saveData() {
			var vm = this;
			incomeRepo.incomeFixed.setData(vm.targetData, () => {
				vm.oldData = JSON.parse(JSON.stringify(vm.targetData));
				vm.doHideModal();
			});
		},
		checkData() {
			var vm = this;
			let lastData = JSON.stringify(vm.checkedData);
			let tempData = JSON.parse(JSON.stringify(vm.targetData));
			tempData.Name = null;
			tempData.StartDate = null;
			tempData.StartDate = null;
			tempData.Amount = null;

			let checkData = JSON.stringify(tempData);

			if (checkData == lastData) return;
			if (tempData.CalType == "None") return;

			vm.checkedData = tempData;

			vm.isChecked = false;

			vm.errorResult = [];
			incomeRepo.incomeFixed.getParamenterCheck(
				vm.targetData,
				function () {
					vm.reviewData();
				},
				function (data) {
					vm.errorResult = data;
				}
			);
		},
		reviewData() {
			var vm = this;
			incomeRepo.incomeFixed.getParameterReview(vm.targetData, function (data) {
				vm.setCalendarEvent(data);
				vm.isChecked = true;
			});
		},
	},
	created() {
		var vm = this;
		vm.doCheckEvent = _.debounce(vm.checkData, 250);
	},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FixedEditor.css">
</style>
