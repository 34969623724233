var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"frame",staticClass:"layoutWrapper"},[_c('div',{staticClass:"largewrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"iconwrapper"},[_c('GlobalIcon',{ref:"largeicon",class:[_vm.largeStatus],attrs:{"size":_vm.largeSize}})],1)]),_c('div',{staticClass:"col-12 mt-4 text-center largetext",class:[_vm.largetext]},[_vm._m(0)])])]),_c('div',{staticClass:"smallwrapper"},[_c('div',{staticClass:"barbg",class:[_vm.barbgStatus]}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"titlewrapper"},[_c('GlobalIcon',{ref:"smallicon",class:[_vm.smallStatus],attrs:{"size":_vm.smallSize}}),_c('div',{staticClass:"pt-1 ms-2 smalltext",class:[_vm.smalltext]},[_vm._m(1)])],1)])])]),_c('div',{staticClass:"movewrapper",style:({
			opacity: _vm.baseOpacity,
			top: _vm.baseTop + 'px',
			left: _vm.baseLeft + 'px',
			pointerEvents: _vm.basePointEvent,
			transition: _vm.baseTrans + 's',
			transitionTimingFunction: _vm.baseTiming,
		})},[_c('GlobalIcon',{attrs:{"size":_vm.baseSize}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('strong',[_vm._v("Cash Tracert")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"m-0"},[_c('strong',[_vm._v("Cash Tracert")])])
}]

export { render, staticRenderFns }