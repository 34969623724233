<template src="./FrameMain.html"></template>

<script>
import store from "@/store";
import FrameTop from "@/components/UI/FrameUI/FrameTop/FrameTop.vue";
import FrameLeft from "@/components/UI/FrameUI/FrameLeft/FrameLeft.vue";
import FrameContent from "@/components/UI/FrameUI/FrameContent/FrameContent.vue";
import FrameBottom from "@/components/UI/FrameUI/FrameBottom/FrameBottom.vue";
import IncomeFixedEditor from "@/components/Income/Fixed/FixedEditor/FixedEditor.vue";
export default {
	name: "FrameMain",
	props: {},
	data() {
		return {
			bgStatus: "",
			modalStatus: "",
		};
	},
	components: {
		FrameTop,
		FrameLeft,
		FrameContent,
		FrameBottom,
		IncomeFixedEditor,
	},
	computed: {
		isModalBoxOpen: {
			get() {
				return store.getters.isModalBoxOpen;
			},
		},
	},
	watch: {
		isModalBoxOpen(newValue) {
			var vm = this;
			vm.setModalBoxOpen(newValue);
		},
	},
	methods: {
		setBgStatus(value) {
			var vm = this;
			vm.bgStatus = value;
		},
		setModalBoxOpen(value) {
			var vm = this;
			if (value) vm.modalStatus = "modalLayer";
			else vm.modalStatus = "modalLayerOut";
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		setTimeout(() => {
			vm.setBgStatus("show");
		}, 10);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FrameMain.css">
</style>
