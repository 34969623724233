<template src="./FrameContent.html"></template>

<script>
import store from "@/store";
import IncomeFixedMain from "@/components/Income/Fixed/FixedMain/FixedMain.vue";
import StoreMain from "@/components/Store/StoreMain/StoreMain.vue";
export default {
	name: "FrameContent",
	props: {},
	data() {
		return {
			showStatus: "",
		};
	},
	components: {
		IncomeFixedMain,
		StoreMain,
	},
	computed: {
		frameName: {
			get() {
				return store.state.frameName;
			},
		},
		framePageList: {
			get() {
				return store.state.framePageList;
			},
		},
	},
	watch: {
		frameName() {
			var vm = this;
			vm.frameChange();
		},
	},
	methods: {
		showLayout() {
			var vm = this;
			vm.showStatus = "show";
		},
		hideLayout() {
			var vm = this;
			vm.showStatus = "hide";
		},
		frameChange() {
			var vm = this;
			let target = vm.framePageList.filter((x) => {
				return vm.frameName.indexOf(x.name) >= 0;
			});
			if (target == null || target.length == 0) return;
			target = target[0];
			vm.hideLayout();
			setTimeout(() => {
				vm.framePageList.forEach((x) => {
					x.page.hideLayout();
				});
				target.page.showLayout();
				setTimeout(() => {
					vm.showLayout();
				}, 50);
			}, 350);
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		store.dispatch("frameContent", vm);
		setTimeout(() => {
			vm.showLayout();
		}, 350);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FrameContent.css">
</style>
