<template>
	<div class="errorWrapper">
		<div v-for="(item, i) in errorList" :key="item.ID + '_' + i">
			<span
				><small
					><i class="fa-solid fa-triangle-exclamation"></i>
					{{ item.Content }}</small
				></span
			>
		</div>
	</div>
</template>
  
<script>
export default {
	name: "ErrorToolTip",
	props: ["data", "id"],
	data() {
		return {
			status: "",
		};
	},
	computed: {
		errorList: {
			get() {
				var vm = this;
				return vm.data.filter((x) => {
					return x.ID.indexOf(vm.id) >= 0;
				});
			},
		},
	},
	watch: {},
	methods: {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.errorWrapper {
	width: 100%;
	color: rgb(240, 50, 50);
}
</style>