import store from "@/store";
import router from "@/router";
import encrypt from "@/utils/encrypt";

export default {
	goToFrame: goToFrame,
	getFrameFromUrl: getFrameFromUrl,
	goToSplit: goToSplit,
	getSplitFromUrl: getSplitFromUrl,
};

function goToFrame(name) {
	encrypt.encryptAES(name, process.env.VUE_APP_FRAME_KEY).then(
		(result) => {
			let aesKey = router.currentRoute.query.frame;
			if (aesKey) {
				let nowName = encrypt.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY);
				if (nowName != name) {
					router.push({ name: "home", query: { frame: result } });
				}
			} else {
				router.push({ name: "home", query: { frame: result } });
			}
		},
		(err) => {
			console.log(err);
		}
	);
}

function getFrameFromUrl() {
	let aesKey = router.currentRoute.query.frame;
	if (!aesKey) {
		store.dispatch("frameChange", "OverView.Home");
		return;
	}
	encrypt
		.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
		.then((result) => {
			store.dispatch("frameChange", result);
		})
		.catch((err) => {
			alert(err.message);
		});
}

function goToSplit(name) {
	encrypt.encryptAES(name, process.env.VUE_APP_FRAME_KEY).then(
		(result) => {
			let query = JSON.parse(JSON.stringify(router.currentRoute.query));
			if (name == "Left") {
				query.subframe = undefined;
			} else {
				query.subframe = result;
			}

			let aesKey = router.currentRoute.query.subframe;
			if (aesKey) {
				encrypt
					.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
					.then((nowName) => {
						if (nowName != name) {
							router.push({
								name: "home",
								query: query,
							});
						}
					})
					.catch((err) => {
						alert(err.message);
					});
			} else {
				router.push({
					name: "home",
					query: query,
				});
			}
		},
		(err) => {
			console.log(err);
		}
	);
}

function getSplitFromUrl() {
	let aesKey = router.currentRoute.query.subframe;
	if (!store.getters.framePageShow) return;
	if (!aesKey) {
		store.getters.framePageShow.page.setSplit("Left");
		return;
	}
	encrypt
		.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
		.then((result) => {
			store.getters.framePageShow.page.setSplit(result);
		})
		.catch((err) => {
			alert(err.message);
		});
}
