<template>
	<div>
		<FrameMain />
	</div>
</template>

<script>
import FrameMain from "@/components/UI/FrameUI/FrameMain/FrameMain.vue";
import frameCtrl from "@/utils/frameCtrl";
export default {
	name: "HomeView",
	data() {
		return {};
	},
	components: {
		FrameMain,
	},
	methods: {},
	mounted() {
		const el = document.querySelector("html");
		el.dataset.fontsize = "";
		setTimeout(() => {
			frameCtrl.goToFrame("Setting.Store");
		}, 1000);
	},
};
</script>
<style lang="less" scoped></style>