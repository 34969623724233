<template>
	<div>
		<div class="base"></div>
		<div id="background" class="bg" :class="[bgStatus]">
			<canvas id="backgroundBubbly" ref="canvasBG"></canvas>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
export default {
	name: "GlobalBg",
	props: {},
	data() {
		return {
			bgStatus: "",
			doResizeEvent: null,
		};
	},
	computed: {
		bubblyOptionMobile: {
			get() {
				return {
					canvas: document.querySelector("#backgroundBubbly"),
					animate: false,
					bubbles: {
						fill: () =>
							`hsla(${Math.random() * 360}, 100%, 50%, ${
								Math.random() * 0.25
							})`,
						shadow: () => ({ blur: 4, color: "#fff" }),
						radius: () => 4 + (Math.random() * window.innerHeight) / 10,
					},
					background: (ctx) => {
						const gradient = ctx.createLinearGradient(
							0,
							0,
							ctx.canvas.width,
							ctx.canvas.height
						);
						gradient.addColorStop(0, "#4c004c");
						gradient.addColorStop(1, "#1a001a");
						return gradient;
					},
				};
			},
		},
		bubblyOption: {
			get() {
				return {
					canvas: document.querySelector("#backgroundBubbly"),
					animate: false,
					bubbles: {
						fill: () =>
							`hsla(${Math.random() * 360}, 100%, 50%, ${
								Math.random() * 0.25
							})`,
						shadow: () => ({ blur: 4, color: "#fff" }),
					},
					background: (ctx) => {
						const gradient = ctx.createLinearGradient(
							0,
							0,
							ctx.canvas.width,
							ctx.canvas.height
						);
						gradient.addColorStop(0, "#4c004c");
						gradient.addColorStop(1, "#1a001a");
						return gradient;
					},
				};
			},
		},
	},
	watch: {},
	methods: {
		createBG() {
			var vm = this;
			let bubbly = window.bubbly;
			let bubblyConfig = {};
			if (window.innerWidth <= 576) bubblyConfig = vm.bubblyOptionMobile;
			else bubblyConfig = vm.bubblyOption;
			if (window.innerWidth > 576) bubbly(bubblyConfig);
		},
		showBG() {
			var vm = this;
			vm.bgStatus = "show";
		},
		hideBG() {
			var vm = this;
			vm.bgStatus = "hide";
		},
		resize() {
			var vm = this;
			if (vm.doResizeEvent != null) vm.doResizeEvent();
		},
		doResize() {
			var vm = this;
			let waitTime = 0;
			if (vm.bgStatus == "show") {
				vm.hideBG();
				waitTime = 450;
			}
			setTimeout(() => {
				vm.$refs.canvasBG.height = window.innerHeight;
				vm.$refs.canvasBG.width = window.innerWidth;
				vm.createBG();
				setTimeout(() => {
					vm.showBG();
				}, 350);
			}, waitTime);
		},
	},
	created() {
		var vm = this;
		vm.doResizeEvent = _.debounce(vm.doResize, 250);
		window.addEventListener("resize", vm.resize);
	},
	destroyed() {
		var vm = this;
		window.removeEventListener("resize", vm.resize);
	},
	mounted: function () {
		var vm = this;
		vm.resize();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.base {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -2;
	background: #4c004c;
}
.bg {
	transition: 0.25s;
	transition-timing-function: ease-in-out;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
	transition: 0.45s;
	filter: blur(16px);
}
@media (max-width: 576px) {
	.bg {
		filter: none;
		background: url("@/assets/mainBg.webp");
		background-position: center center;
		background-size: cover;
	}
}
.bg.show {
	opacity: 1;
}
.bg.hide {
	opacity: 0;
}
</style>
