<template src="./FrameTop.html"></template>

<script>
import store from "@/store";
export default {
	name: "FrameTop",
	props: {},
	data() {
		return {
			showStatus: "",
			isDisableBlur: false,
		};
	},
	computed: {
		userEntity: {
			get() {
				return store.state.rootStore.userEntity;
			},
		},
		isframePageRight: {
			get() {
				return store.getters.isframePageRight;
			},
		},
		framePageShow: {
			get() {
				return store.getters.framePageShow;
			},
		},
		frameTitle: {
			get() {
				var vm = this;
				let result = "Cash Tracert";
				if (vm.framePageShow == null) return result;
				if (vm.framePageShow.title != null) {
					if (vm.framePageShow.title.length > 0)
						result = vm.framePageShow.title[0];
				}
				return result;
			},
		},
		frameTitleSub: {
			get() {
				var vm = this;
				let result = [];
				if (vm.framePageShow == null) return result;
				if (vm.framePageShow.title != null) {
					if (vm.framePageShow.title.length > 1) {
						for (let i = 1; i < vm.framePageShow.title.length; i++)
							result.push(vm.framePageShow.title[i]);
					}
				}
				return result;
			},
		},
		isModalBoxOpen: {
			get() {
				return store.getters.isModalBoxOpen;
			},
		},
		isWidthPhone: {
			get() {
				let result = false;
				if (window.innerWidth <= 576) result = true;
				return result;
			},
		},
	},
	watch: {
		isModalBoxOpen(newValue) {
			var vm = this;
			vm.setModalBoxOpen(newValue);
		},
	},
	methods: {
		showLayout() {
			var vm = this;
			vm.showStatus = "show";
		},
		hideLayout() {
			var vm = this;
			vm.showStatus = "hide";
		},
		setMenuStatus() {
			if (store.state.menuStatus == "open")
				store.dispatch("rootStore/setMenuStatus", "close");
			else store.dispatch("rootStore/setMenuStatus", "open");
		},
		onBackClick() {
			var vm = this;
			if (vm.framePageShow == null) return;
			history.go(-1);
		},
		setModalBoxOpen(value) {
			var vm = this;
			if (vm.isWidthPhone) {
				if (value) {
					vm.isDisableBlur = true;
				} else
					setTimeout(() => {
						vm.isDisableBlur = false;
					}, 750);
			}
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		setTimeout(() => {
			vm.showLayout();
		}, 250);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FrameTop.css">
</style>
