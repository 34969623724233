<template src="./StoreMain.html"></template>

<script>
import FramePage from "@/components/UI/FrameUI/FramePage/FramePage.vue";
import SplitTop from "@/components/UI/FrameUI/SplitUI/SplitTop/SplitTop.vue";
import StoreManageList from "@/components/Store/StoreManage/StoreManageList/StoreManageList.vue";
import StoreManageDetail from "@/components/Store/StoreManage/StoreManageDetail/StoreManageDetail.vue";
import ExpensesMethodManageList from "@/components/Store/ExpensesMethodManage/ExpensesMethodManageList/ExpensesMethodManageList.vue";
import ExpensesMethodManageDetail from "@/components/Store/ExpensesMethodManage/ExpensesMethodManageDetail/ExpensesMethodManageDetail.vue";
import ExpensesManageList from "@/components/Store/ExpensesManage/ExpensesManageList/ExpensesManageList.vue";
import ExpensesManageDetail from "@/components/Store/ExpensesManage/ExpensesManageDetail/ExpensesManageDetail.vue";
import frameCtrl from "@/utils/frameCtrl";
import tabCtrl from "@/utils/tabCtrl";
export default {
	name: "StoreMain",
	props: {},
	data() {
		return {
			title: ["設定", "存款支出方法"],
			showEvent: null,
			hideEvent: null,
			contentStatus: "hide",

			tabNameList: [
				{
					name: "存放位置",
					value: "Store",
				},
				{
					name: "支出方式",
					value: "Expenses",
				},
				{
					name: "支出載體",
					value: "ExpensesMethod",
				},
			],
			tabDefault: "Store",
			tabTarget: "",
			tabContent: "",
		};
	},
	components: {
		FramePage,
		SplitTop,
		StoreManageList,
		StoreManageDetail,
		ExpensesMethodManageList,
		ExpensesMethodManageDetail,
		ExpensesManageList,
		ExpensesManageDetail,
	},
	computed: {},
	watch: {},
	methods: {
		doShowEvent() {
			var vm = this;
			vm.setTabTarget(vm.tabDefault);
		},
		doHideEvent() {},
		setTabTarget(input) {
			tabCtrl.setTabTarget(input.value);
		},
		getTabTarget(input) {
			var vm = this;
			let target = input;
			if (target == null || target == "") target = vm.tabDefault;
			vm.tabTarget = target;
			vm.contentStatus = "hide";
			setTimeout(() => {
				vm.tabContent = target;
				setTimeout(() => {
					vm.contentStatus = "show";
				}, 100);
			}, 350);
		},
	},
	created() {
		var vm = this;
		vm.showEvent = vm.doShowEvent;
		vm.hideEvent = vm.doHideEvent;
	},
	destroyed() {},
	mounted: function () {
		frameCtrl.goToFrame("");
		setTimeout(() => {
			frameCtrl.goToFrame("Setting.Store");
		}, 1000);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./StoreMain.css">
</style>
