<template src="./SplitTop.html"></template>

<script>
export default {
	name: "SplitTop",
	props: {},
	data() {
		return {};
	},
	components: {},
	computed: {},
	watch: {},
	filters: {},
	methods: {},
	created() {},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./SplitTop.css">
</style>
