import { render, staticRenderFns } from "./ModalBox.html?vue&type=template&id=5549bfb6&scoped=true&"
import script from "./ModalBox.vue?vue&type=script&lang=js&"
export * from "./ModalBox.vue?vue&type=script&lang=js&"
import style0 from "./ModalBox.css?vue&type=style&index=0&id=5549bfb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5549bfb6",
  null
  
)

export default component.exports