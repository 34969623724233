var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"icon-wrapper",style:({
			width: (_vm.size ?? 128) + 'px',
			height: (_vm.size ?? 128) + 'px',
		})},[_c('div',{staticClass:"shadow-wrapper",class:[_vm.status]},[_c('div',{ref:"shadowBox",staticClass:"shadow",style:({
					borderRadius: (_vm.radius ?? 38) + 'px',
				})})]),_c('div',{ref:"bgBox",staticClass:"bg",style:({
				borderRadius: (_vm.radius ?? 38) + 'px',
			})}),_c('div',{staticClass:"img-wrapper",class:[_vm.status]},[_c('img',{style:({
					width: (_vm.imgSize ?? 128) + 'px',
					height: (_vm.imgSize ?? 128) + 'px',
				}),attrs:{"src":require("@/assets/icon.png")}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }