<template>
	<div>
		<div class="startup-wrapper" :class="[initStatus]">
			<div class="startup-content">
				<div class="row">
					<div class="col-12 mt-3 text-center">
						<GlobalIcon size="128" />
					</div>
					<div class="col-12 mt-4" v-show="!showReload">
						<div class="startup-spin">
							<LoadSpin></LoadSpin>
						</div>
					</div>
					<div class="col-12 mt-3 text-center" v-show="showReload">
						無法連線到伺服器
					</div>
					<div class="col-12 mt-3 text-center" v-show="showReload">
						<button class="btn btn-danger" @click="callGetUserEntity">
							<i class="fa-solid fa-rotate"></i> 重新載入
						</button>
					</div>
				</div>
			</div>
		</div>
		<WindowFrame ref="windowframe">
			<WindowContent ref="windowloading" padding="nopadding">
				<LoadingWindow />
			</WindowContent>
			<WindowContent ref="windowlogin" padding="nopadding">
				<LoginWindow ref="login" />
			</WindowContent>
			<WindowContent ref="windowregister" padding="nopadding">
				<RegisterWindow ref="register" />
			</WindowContent>
		</WindowFrame>
	</div>
</template>
  
<script>
import WindowFrame from "@/components/UI/WindowUI/WindowFrame/WindowFrame.vue";
import WindowContent from "@/components/UI/WindowUI/WindowContent/WindowContent.vue";
import LoadingWindow from "@/components/Startup/LoadingWindow/LoadingWindow.vue";
import LoginWindow from "@/components/Startup/LoginWindow/LoginWindow.vue";
import RegisterWindow from "@/components/Startup/RegisterWindow/RegisterWindow.vue";
import GlobalIcon from "@/components/Global/GlobalIcon.vue";
import LoadSpin from "@/components/Global/LoadSpin.vue";
import store from "@/store";
import router from "@/router";
import userApi from "@/api/user";
import userEntity from "@/utils/userEntity";
import userToken from "@/utils/userToken";
import msgCtrl from "@/utils/msgCtrl";
import { appInit } from "@/repos/init";

export default {
	name: "StartupView",
	data() {
		return {
			isWindowShow: false,
			initStatus: "",
			showReload: false,
			contentList: [],
		};
	},
	components: {
		LoadingWindow,
		LoginWindow,
		RegisterWindow,

		WindowFrame,
		WindowContent,

		GlobalIcon,
		LoadSpin,
	},
	methods: {
		showLoading() {
			var vm = this;
			vm.initStatus = "hide";
			vm.isWindowShow = true;
			setTimeout(() => {
				vm.$refs.windowframe.setWindowSize(vm.$refs.windowloading, false);
				vm.$refs.windowloading.showContent();
				vm.$refs.windowframe.setWindowShow();
			}, 250);
		},
		showLogin() {
			var vm = this;
			vm.initStatus = "hide";
			vm.isWindowShow = true;
			setTimeout(() => {
				vm.$refs.windowframe.setWindowSize(vm.$refs.windowlogin, false);
				vm.$refs.windowlogin.showContent();
				vm.$refs.windowframe.setWindowShow();
			}, 250);
		},
		hideWindow() {
			var vm = this;
			vm.isWindowShow = false;
			vm.$refs.windowframe.setWindowHidden();
		},
		switchRegister(email) {
			var vm = this;
			vm.$refs.register.setInital(email);
			vm.$refs.windowframe.setContentSwitch(
				vm.contentList,
				vm.$refs.windowregister
			);
		},
		switchLogin() {
			var vm = this;
			vm.$refs.windowframe.setContentSwitch(
				vm.contentList,
				vm.$refs.windowlogin
			);
		},
		setContentList() {
			var vm = this;
			vm.contentList.push(vm.$refs.windowloading);
			vm.contentList.push(vm.$refs.windowlogin);
			vm.contentList.push(vm.$refs.windowregister);
		},
		callGetUserEntity() {
			var vm = this;
			vm.showReload = false;
			userApi
				.getUserEntity()
				.then((res) => {
					if (!res.IsLogin) {
						userEntity.setUserEntityClear();
						userToken.setUserTokenClear();
						if (!vm.isWindowShow) {
							vm.showLogin();
						} else {
							vm.switchLogin();
							vm.$refs.login.email = "";
						}
						return;
					}
					if (res.IsLogin) {
						let waitTime = 0;
						if (vm.isWindowShow) {
							vm.hideWindow();
							waitTime = 350;
						}
						if (vm.initStatus == "show") {
							vm.initStatus = "hide";
							waitTime = 250;
						}
						store.dispatch("rootStore/userEntityLoad", res);
						setTimeout(() => {
							vm.goToStartUpBack();
						}, waitTime);
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線取得初始化資訊時出現錯誤，請確認裝置是否能進行連線，或是稍後再重試",
						msgCtrl.notifyDelay.default
					);
					vm.showReload = true;
				})
				.finally(() => {
					if (vm.btnSendStatus == "wait") vm.setSendMailBtnStatus("normal");
				});
		},
		goToStartUpBack() {
			appInit();
			if (store.state.rootStore.router.startupBack == null) {
				if (
					store.state.rootStore.userEntity == null ||
					!store.state.rootStore.userEntity.IsLogin
				) {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"邏輯錯誤",
						"初始化過程出現邏輯錯誤，登入資訊沒有正確寫入到瀏覽器，請重新整理整個頁面",
						msgCtrl.notifyDelay.default
					);
				} else {
					router.push({ name: "home", replace: true });
				}
			} else {
				if (
					store.state.rootStore.userEntity == null ||
					!store.state.rootStore.userEntity.IsLogin
				) {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"邏輯錯誤",
						"初始化過程出現邏輯錯誤，登入資訊沒有正確寫入到瀏覽器，請重新整理整個頁面",
						msgCtrl.notifyDelay.default
					);
				} else {
					let name = store.state.rootStore.router.startupBack.toName;
					let query = store.state.rootStore.router.startupBack.query;
					store.dispatch("rootStore/routerStartupBack", null);
					router.push({
						name: name,
						query: query,
						replace: true,
					});
				}
			}
		},
	},
	mounted() {
		var vm = this;
		vm.setContentList();
		vm.initStatus = "show";
		vm.callGetUserEntity();
		store.dispatch("rootStore/programInitial");
		const el = document.querySelector("html");
		el.dataset.fontsize = "small";
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.startup-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	opacity: 0;
	transform: scale(0.95);
	transition: 0.35s;
	transition-timing-function: ease-in-out;
}
.startup-wrapper.show {
	opacity: 1;
	pointer-events: all;
	transform: scale(1);
	transition: 0.35s;
}
.startup-wrapper.hide {
	opacity: 0;
	pointer-events: none;
	transform: scale(0.75);
	transition: 0.25s;
}

.startup-content {
	width: 240px;
	padding-bottom: 48px;
	color: var(--text-color-hoveron);
}
@media screen and (max-width: 576px) {
	.startup-content {
		padding-bottom: 128px;
	}
}
.startup-spin {
	width: 128px;
	margin: auto;
}
</style>
