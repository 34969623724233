<template src="./ExpensesMethodManageDetail.html"></template>

<script>
import SplitRight from "@/components/UI/FrameUI/SplitUI/SplitRight/SplitRight.vue";
export default {
	name: "ExpensesMethodManageDetail",
	props: {},
	data() {
		return {};
	},
	components: {
		SplitRight,
	},
	computed: {},
	watch: {},
	methods: {},
	created() {},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./ExpensesMethodManageDetail.css">
</style>
