<template src="./CalendarBox.html"></template>
  
<script>
import moment from "moment";
import calendarRepo from "@/repos/calendarRepo";
export default {
	name: "CalendarBox",
	props: ["shadow"],
	data() {
		return {
			frameData: {
				height: 250,
				topHeight: 24,
			},
			weekDay: ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
			style: {
				weekDayWidth: 0,
			},
			calendarBase: [],
			calendarEvent: [],
			currentView: {
				year: 2023,
				month: 8,
			},
			currentDate: {
				year: 2023,
				month: 8,
				day: 31,
			},
		};
	},
	computed: {
		rowHeight: {
			get() {
				var vm = this;
				let result = 10;
				if (vm.calendarBase.length != 0) result = 100 / vm.calendarBase.length;
				return result;
			},
		},
	},
	watch: {},
	methods: {
		resetData() {
			var vm = this;
			vm.currentView.year = parseInt(moment().format("YYYY"));
			vm.currentView.month = parseInt(moment().format("MM"));
			vm.calendarBase = [];
			vm.calendarEvent = [];
			vm.getCalendarBase();
		},
		getCalendarBase() {
			var vm = this;
			vm.calendarBase = calendarRepo.getTotalFullWeekOfMonth(
				vm.currentView.year,
				vm.currentView.month
			);
		},
		setCalendarEvent(input) {
			var vm = this;
			vm.calendarEvent = input;
		},
		getEvent(day) {
			var vm = this;
			let result = vm.calendarEvent.filter((x) => {
				let s = moment(moment(x.start).format("YYYY/MM/DD"));
				let e = moment(moment(x.end).format("YYYY/MM/DD"));
				let d = moment(day.date);
				if (d >= s && d <= e) {
					return true;
				}
				return false;
			});
			return result;
		},
		nextMonth() {
			var vm = this;
			let d = moment(
				vm.currentView.year + "/" + vm.currentView.month + "/1"
			).add(1, "months");
			vm.currentView.year = parseInt(d.format("YYYY"));
			vm.currentView.month = parseInt(d.format("MM"));
			vm.getCalendarBase();
		},
		prevMonth() {
			var vm = this;
			let d = moment(
				vm.currentView.year + "/" + vm.currentView.month + "/1"
			).add(-1, "months");
			vm.currentView.year = parseInt(d.format("YYYY"));
			vm.currentView.month = parseInt(d.format("MM"));
			vm.getCalendarBase();
		},
		onResize(input) {
			var vm = this;
			let target = input[0];
			const cr = target.contentRect;
			let frameH = (parseInt(cr.width.toString()) / 10) * 9;
			if (vm.frameData.height != frameH) vm.frameData.height = frameH;
			let headerH = parseInt(cr.width.toString()) / 6 / 2;
			if (vm.frameData.topHeight != headerH) vm.frameData.topHeight = headerH;
		},
	},
	created() {},
	mounted() {
		var vm = this;
		vm.resetData();
		const observer = new ResizeObserver(vm.onResize);

		observer.observe(vm.$refs.calendarFrame);
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./CalendarBox.css">
</style>