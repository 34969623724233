window.bubbly = function (t = {}) {
	const e =
			t.canvas ??
			(() => {
				let t = document.createElement("canvas");
				return (
					t.setAttribute(
						"style",
						"position:fixed;z-index:-1;left:0;top:0;min-width:100vw;min-height:100vh;"
					),
					(t.width = window.innerWidth),
					(t.height = window.innerHeight),
					document.body.appendChild(t),
					t
				);
			})(),
		o = e.getContext("2d"),
		{
			compose: a,
			bubbles: i,
			background: r,
			animate: n,
		} = {
			compose: t.compose ?? "lighter",
			bubbles: Object.assign(
				{
					count: Math.floor(0.02 * (e.width + e.height)),
					radius: () => 4 + (Math.random() * window.innerWidth) / 25,
					fill: () => `hsla(0, 0%, 100%, ${0.1 * Math.random()})`,
					angle: () => Math.random() * Math.PI * 2,
					velocity: () => 0.1 + 0.5 * Math.random(),
					shadow: () => null,
					stroke: () => null,
				},
				t.bubbles ?? {}
			),
			background: t.background ?? (() => "#2AE"),
			animate: !1 !== t.animate,
		};
	i.objectCreator =
		t.bubbles?.objectCreator ??
		(() => ({
			r: i.radius(),
			f: i.fill(),
			x: Math.random() * e.width,
			y: Math.random() * e.height,
			a: i.angle(),
			v: i.velocity(),
			sh: i.shadow(),
			st: i.stroke(),
			draw: (t, e) => {
				e.sh && ((t.shadowColor = e.sh.color), (t.shadowBlur = e.sh.blur)),
					(t.fillStyle = e.f),
					t.beginPath(),
					t.arc(e.x, e.y, e.r, 0, 2 * Math.PI),
					t.fill(),
					e.st &&
						((t.strokeStyle = e.st.color),
						(t.lineWidth = e.st.width),
						t.stroke());
			},
		}));
	let h = Array.from({ length: i.count }, i.objectCreator);
	requestAnimationFrame(function t() {
		if (null === e.parentNode) return (h = []), cancelAnimationFrame(t);
		n && requestAnimationFrame(t);
		(o.globalCompositeOperation = "source-over"),
			(o.fillStyle = r(o)),
			o.fillRect(0, 0, e.width, e.height),
			(o.globalCompositeOperation = a);
		for (const t of h)
			t.draw(o, t),
				(t.x += Math.cos(t.a) * t.v),
				(t.y += Math.sin(t.a) * t.v),
				t.x - t.r > e.width && (t.x = -t.r),
				t.x + t.r < 0 && (t.x = e.width + t.r),
				t.y - t.r > e.height && (t.y = -t.r),
				t.y + t.r < 0 && (t.y = e.height + t.r);
	});
};
