<template src="./LoginWindow.html"></template>
  
<script>
import GlobalIconTrans from "@/components/Global/GlobalIconTrans.vue";
import regexCheck from "@/utils/regexCheck";
import loginApi from "@/api/login";
import userToken from "@/utils/userToken";
import msgCtrl from "@/utils/msgCtrl";
import _ from "lodash";
export default {
	name: "LoginWindow",
	props: {},
	data() {
		return {
			iconLargeSize: 96,
			contentTop: 190,
			pageName: "welcome",
			pageStatus: {
				welcome: "goshow",
				verify: "gohide",
				finish: "gohide",
			},
			btnLoginStatus: "normal",
			btnVerifyStatus: "normal",
			email: "",
			displayName: "",
			verifyUID: "",
			dispatchCheckEmail: null,
			activeKeyList: {
				key1: "",
				key2: "",
				key3: "",
				key4: "",
				key5: "",
				key6: "",
			},
		};
	},
	components: {
		GlobalIconTrans,
	},
	computed: {
		viewObj: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		activeKey1: {
			get() {
				var vm = this;
				return vm.activeKeyList.key1;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key1 = value.toUpperCase();
			},
		},
		activeKey2: {
			get() {
				var vm = this;
				return vm.activeKeyList.key2;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key2 = value.toUpperCase();
			},
		},
		activeKey3: {
			get() {
				var vm = this;
				return vm.activeKeyList.key3;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key3 = value.toUpperCase();
			},
		},
		activeKey4: {
			get() {
				var vm = this;
				return vm.activeKeyList.key4;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key4 = value.toUpperCase();
			},
		},
		activeKey5: {
			get() {
				var vm = this;
				return vm.activeKeyList.key5;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key5 = value.toUpperCase();
			},
		},
		activeKey6: {
			get() {
				var vm = this;
				return vm.activeKeyList.key6;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key6 = value.toUpperCase();
			},
		},
		activeKey: {
			get() {
				var vm = this;
				return (
					vm.activeKey1 +
					vm.activeKey2 +
					vm.activeKey3 +
					vm.activeKey4 +
					vm.activeKey5 +
					vm.activeKey6
				);
			},
		},
		isCanVerify: {
			get() {
				var vm = this;
				let result = true;
				if (vm.activeKey1 == null || vm.activeKey1 == "") result = false;
				if (vm.activeKey2 == null || vm.activeKey2 == "") result = false;
				if (vm.activeKey3 == null || vm.activeKey3 == "") result = false;
				if (vm.activeKey4 == null || vm.activeKey4 == "") result = false;
				if (vm.activeKey5 == null || vm.activeKey5 == "") result = false;
				if (vm.activeKey6 == null || vm.activeKey6 == "") result = false;

				return result;
			},
		},
	},
	watch: {
		email() {
			var vm = this;
			vm.onEmailChange();
		},
		pageName(newValue, oldValue) {
			var vm = this;

			if (newValue == "welcome" || newValue == "finish") {
				vm.contentTop = 190;
				vm.$refs.iconTrans.setBarToIcon();
			} else {
				vm.contentTop = 32;
				vm.$refs.iconTrans.setIconToBar();
			}

			let indexOld = -1;
			let indexNew = -1;
			let statusOld = "gohide";
			let statusNew = "goshow";
			let keys = Object.keys(vm.pageStatus);
			keys.forEach((key, i) => {
				if (key == oldValue) indexOld = i;
				if (key == newValue) indexNew = i;
			});
			if (indexOld < indexNew) {
				statusOld = "gohide";
				statusNew = "goshow";
			} else {
				statusOld = "backhide";
				statusNew = "backshow";
			}
			keys.forEach((key) => {
				if (key == oldValue) {
					vm.pageStatus[key] = statusOld;
					return;
				}
				if (key == newValue) {
					vm.pageStatus[key] = statusNew;
					return;
				}
			});
		},
	},
	methods: {
		setPage(value) {
			var vm = this;
			vm.pageName = value;
			vm.pageBack = false;
		},
		setPageBack(value) {
			var vm = this;
			vm.pageName = value;
			vm.pageBack = true;
		},
		setInital(value) {
			var vm = this;
			vm.email = value;
			vm.setPage("welcome");
			vm.btnLoginStatus = "normal";
			vm.btnVerifyStatus = "normal";
			vm.displayName = "";
			vm.verifyUID = "";
			vm.activeKey1 =
				vm.activeKey2 =
				vm.activeKey3 =
				vm.activeKey4 =
				vm.activeKey5 =
				vm.activeKey6 =
					"";
		},
		setBtnLoginStatus(value) {
			var vm = this;
			vm.btnLoginStatus = value;
		},
		setVerifyBtnStatus(value) {
			var vm = this;
			vm.btnVerifyStatus = value;
		},
		onEmailChange() {
			var vm = this;
			if (!regexCheck.regexCheckEmail(vm.email)) {
				vm.setBtnLoginStatus("normal");
				return;
			}
			if (vm.dispatchCheckEmail != null) vm.dispatchCheckEmail();
		},
		onRegisterClick() {
			var vm = this;
			vm.viewObj.switchRegister(vm.email);
		},
		onLoginClick() {
			var vm = this;
			vm.callLoginUser();
		},
		callCheckEmail() {
			var vm = this;

			vm.setBtnLoginStatus("wait");
			loginApi
				.checkEmail({
					Email: vm.email,
				})
				.then((res) => {
					if (!regexCheck.regexCheckEmail(vm.email)) {
						vm.setBtnLoginStatus("normal");
						return;
					}
					if (res.Success) {
						if (res.Action == "Login") {
							vm.setBtnLoginStatus("login");
							return;
						}
						if (res.Action == "Register") {
							vm.setBtnLoginStatus("register");
							return;
						}
						vm.setBtnLoginStatus("normal");
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"檢測失敗",
							"進行 Email 檢查時發生錯誤，請稍後再重試",
							msgCtrl.notifyDelay.default
						);
						vm.setBtnLoginStatus("normal");
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行 Email 檢查時發生錯誤，請稍後再重試",
						msgCtrl.notifyDelay.default
					);
					vm.setBtnLoginStatus("normal");
				})
				.finally(() => {
					if (vm.btnLoginStatus == "wait") vm.setBtnLoginStatus("normal");
				});
		},
		callLoginUser() {
			var vm = this;

			if (!regexCheck.regexCheckEmail(vm.email)) {
				vm.setBtnLoginStatus("normal");
				return;
			}

			vm.setBtnLoginStatus("wait");
			loginApi
				.loginUser({
					Email: vm.email,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "NotEmail") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"登入錯誤",
								"目前輸入的 Email 帳號不符合格式，如果錯誤持續發生，請重新整理並再次重試",
								msgCtrl.notifyDelay.default
							);
							vm.setBtnLoginStatus("normal");
							return;
						}

						if (res.Action == "Register") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"尚未註冊",
								"目前輸入的 Email 尚未註冊，請開啟註冊界面，我們將為您註冊屬於您的帳號",
								msgCtrl.notifyDelay.default
							);
							vm.setBtnLoginStatus("register");
							return;
						}

						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"登入失敗",
								"登入的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.setBtnLoginStatus("login");
							return;
						}
						if (res.Action == "Send") {
							vm.verifyUID = res.Data[0];
							vm.displayName = res.Data[1];
							vm.setPage("verify");
							vm.$refs["key1"].focus();
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"登入失敗",
							"進行登入時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
							msgCtrl.notifyDelay.default
						);
						vm.setBtnLoginStatus("login");
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線登入時發生錯誤，請稍後再重試，如果問題持續發生，請聯絡我們",
						msgCtrl.notifyDelay.default
					);
					vm.setBtnLoginStatus("normal");
				})
				.finally(() => {
					if (vm.btnLoginStatus == "wait") vm.setBtnLoginStatus("normal");
				});
		},
		callCheckActiveKey() {
			var vm = this;

			vm.setVerifyBtnStatus("wait");
			loginApi
				.checkActiveKey({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "Verified") {
							vm.setPage("finish");
							vm.callSetLoginData();
						}
						if (res.Action == "Unverified") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證失敗",
								"檢查輸入的驗證碼是否與 Email 中收到的驗證碼一致，並請再試一次",
								msgCtrl.notifyDelay.default
							);
							vm.setVerifyBtnStatus("normal");
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"驗證失敗",
							"驗證驗證碼的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
							msgCtrl.notifyDelay.default
						);
						vm.setVerifyBtnStatus("normal");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼檢查時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setVerifyBtnStatus("normal");
				})
				.finally(() => {
					if (vm.btnVerifyStatus == "wait") vm.setVerifyBtnStatus("normal");
				});
		},
		callSetLoginData() {
			var vm = this;

			loginApi
				.setLoginData({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"登入失敗",
								"進行登入的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.setInital();
							return;
						}
						if (res.Action == "Timeout") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證已過期",
								"您的驗證資訊已經逾期，重新進行登入",
								msgCtrl.notifyDelay.default
							);
							vm.setInital();
							return;
						}
						if (res.Action == "Success") {
							userToken.setUserToken(res.Data[0]);
							setTimeout(() => {
								vm.viewObj.hideWindow();
								setTimeout(() => {
									vm.viewObj.callGetUserEntity();
								}, 500);
							}, 3000);
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"驗證失敗",
							"驗證驗證碼的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
							msgCtrl.notifyDelay.default
						);
						vm.setVerifyBtnStatus("normal");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼檢查時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setVerifyBtnStatus("normal");
				})
				.finally(() => {
					if (vm.btnVerifyStatus == "wait") vm.setVerifyBtnStatus("normal");
				});
		},
		onActiveKeyDown(e) {
			var vm = this;
			if (e.key === "Backspace" || e.key === "Delete") {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value != "") return;
				if (keyNow == 1) return;
				let backName = "key" + (keyNow - 1).toString();
				vm.$refs[backName].focus();
			}
		},
		onActiveKeyUp(e) {
			var vm = this;
			if (e.key === "Backspace" || e.key === "Delete") {
				return;
			} else {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value == "") return;
				if (keyNow == 6) return;
				let backName = "key" + (keyNow + 1).toString();
				vm.$refs[backName].focus();
			}
		},
	},
	created() {
		var vm = this;
		vm.dispatchCheckEmail = _.debounce(vm.callCheckEmail, 500);
	},
	mounted() {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./LoginWindow.css">
</style>