<template src="./SettingMenu.html"></template>

<script>
import store from "@/store";
import frameCtrl from "@/utils/frameCtrl";
export default {
	name: "SettingMenu",
	props: ["layoutstatus"],
	data() {
		return {
			folderStatus: [],
			frameType: "Setting",
		};
	},
	computed: {},
	watch: {},
	methods: {
		frameChange(target) {
			var vm = this;
			let frameName = vm.frameType + "." + target;
			frameCtrl.goToFrame(frameName);
		},
		frameCheck(target) {
			var vm = this;
			let frameName = vm.frameType + "." + target;
			let result = false;
			if (store.state.frameName == frameName) result = true;
			return result;
		},
		taggleFolder(target, type) {
			var vm = this;

			let data = vm.getFolderStatus(target);
			let result = !data.value;

			if (type) {
				let typeList = vm.getFolderStatusByType(type);
				typeList.forEach((item) => {
					item.value = false;
				});
			}

			data.value = result;
		},
		getFolderStatus(target) {
			var vm = this;
			let data = vm.folderStatus.filter((x) => {
				return x.target == target;
			});
			if (data == null || data.length == 0) {
				data = { target: target, value: false };
				vm.folderStatus.push(data);
			} else {
				data = data[0];
			}
			return data;
		},
		getFolderStatusByType(target) {
			var vm = this;
			let data = vm.folderStatus.filter((x) => {
				return x.target.indexOf(target) >= 0;
			});
			return data;
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./SettingMenu.css">
</style>
