<template src="./FixedMain.html"></template>

<script>
import FramePage from "@/components/UI/FrameUI/FramePage/FramePage.vue";
import IncomeFixedList from "@/components/Income/Fixed/FixedList/FixedList.vue";
import IncomeFixedDetail from "@/components/Income/Fixed/FixedDetail/FixedDetail.vue";
import incomeRepo from "@/repos/incomeRepo";
export default {
	name: "FixedMain",
	props: {},
	data() {
		return {
			title: ["收入", "固定收入"],
			showEvent: null,
			hideEvent: null,
		};
	},
	components: {
		FramePage,
		IncomeFixedList,
		IncomeFixedDetail,
	},
	computed: {},
	watch: {},
	methods: {
		doShowEvent() {
			var vm = this;
			vm.$refs.fixedList.setSelectMode(false);
			incomeRepo.incomeFixed.getDataList(() => {
				vm.$refs.fixedList.setScrollBar();
			});
		},
		doHideEvent() {
			var vm = this;
			vm.$refs.fixedList.setSelectMode(false);
		},
	},
	created() {
		var vm = this;
		vm.showEvent = vm.doShowEvent;
		vm.hideEvent = vm.doHideEvent;
	},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FixedMain.css"></style>
