import store from "@/store";
import router from "@/router";
import encrypt from "@/utils/encrypt";

export default {
	setTabTarget: setTabTarget,
	getTabTargetFromUrl: getTabTargetFromUrl,
};

function setTabTarget(name) {
	store.dispatch("tabChange", name);
	encrypt.encryptAES(name, process.env.VUE_APP_FRAME_KEY).then(
		(result) => {
			let query = JSON.parse(JSON.stringify(router.currentRoute.query));
			let aesKey = router.currentRoute.query.targettab;
			if (aesKey) {
				encrypt
					.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
					.then((nowName) => {
						if (nowName != name) {
							query.targettab = result;
							router.push({
								name: "home",
								query: query,
							});
						}
					})
					.catch((err) => {
						alert(err.message);
					});
			} else {
				query.targettab = result;
				router.push({
					name: "home",
					query: query,
				});
			}
		},
		(err) => {
			console.log(err);
		}
	);
}

function getTabTargetFromUrl() {
	let aesKey = router.currentRoute.query.targettab;
	if (!aesKey) {
		let framePageShow = store.getters.framePageShow;
		if (framePageShow == null) return;
		if (framePageShow.page.$parent.getTabTarget == null) return;
		framePageShow.page.$parent.getTabTarget("");
		return;
	}
	encrypt
		.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
		.then((result) => {
			let framePageShow = store.getters.framePageShow;
			if (framePageShow == null) return;
			if (framePageShow.page.$parent.getTabTarget == null) return;
			framePageShow.page.$parent.getTabTarget(result);
		})
		.catch((err) => {
			alert(err.message);
		});
}
