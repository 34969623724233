<template>
	<div class="main-wrapper">
		<div
			class="icon-wrapper"
			:style="{
				width: (size ?? 128) + 'px',
				height: (size ?? 128) + 'px',
			}"
		>
			<div class="shadow-wrapper" :class="[status]">
				<div
					class="shadow"
					ref="shadowBox"
					:style="{
						borderRadius: (radius ?? 38) + 'px',
					}"
				></div>
			</div>
			<div
				class="bg"
				ref="bgBox"
				:style="{
					borderRadius: (radius ?? 38) + 'px',
				}"
			></div>
			<div class="img-wrapper" :class="[status]">
				<img
					src="@/assets/icon.png"
					:style="{
						width: (imgSize ?? 128) + 'px',
						height: (imgSize ?? 128) + 'px',
					}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "GlobalIcon",
	props: ["size"],
	data() {
		return {
			status: "",
			imgSize: 128,
			radius: 56,
		};
	},
	computed: {},
	watch: {
		size() {
			var vm = this;
			vm.calSize();
		},
	},
	methods: {
		calSize() {
			var vm = this;
			if (vm.size >= 144) {
				vm.radius = vm.size / 3;
				vm.imgSize = vm.size * 0.9;
				return;
			}
			if (vm.size >= 64) {
				vm.radius = vm.size / 3.5;
				vm.imgSize = vm.size;
				return;
			}
			vm.radius = vm.size / 4;
			vm.imgSize = vm.size;
			vm.status = "small";
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		setTimeout(() => {
			vm.calSize();
		}, 100);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-wrapper {
	display: inline-block;
}
.icon-wrapper {
	position: relative;
	width: 128px;
	height: 128px;
	transition: 0.45s;
}
.shadow-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	filter: blur(8px);
	transition: 0.45s;
}
.shadow-wrapper.small {
	filter: blur(0);
}
.shadow {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 24px;
	background: rgba(0, 0, 0, 0.375);
	transition: 0.45s;
}
.bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 24px;
	background: rgb(107, 107, 255);
	background: linear-gradient(
		315deg,
		rgba(107, 107, 255, 1) 0%,
		rgba(94, 94, 255, 1) 15%,
		rgba(81, 81, 255, 1) 40%,
		rgba(52, 52, 255, 1) 70%,
		rgba(60, 60, 255, 1) 100%
	);
	transition: 0.45s;
}
.img-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.45s;
}
.img-wrapper > img {
	transition: 0.45s;
}
</style>
