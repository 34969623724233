<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<button class="btn btn-primary me-1" @click="showMsgBox">
						MsgBox Show
					</button>
					<button class="btn btn-primary me-1" @click="showNotify">
						Notify Show
					</button>
				</div>
				<div class="col-12 mt-1">
					<button class="btn btn-primary" @click="showWindow">
						Window Show
					</button>
					<button class="btn btn-primary" @click="hideWindow">
						Window Hide
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center">
					<GlobalIcon size="64" />
				</div>
			</div>
		</div>
		<WindowFrame ref="windowframe">
			<WindowContent ref="windowcontent"
				>------------------- 測試視窗內容 -------------------<br />-------------------
				測試視窗內容 -------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br />------------------- 測試視窗內容
				-------------------<br
			/></WindowContent>
		</WindowFrame>
	</div>
</template>

<script>
import WindowFrame from "@/components/UI/WindowUI/WindowFrame/WindowFrame.vue";
import WindowContent from "@/components/UI/WindowUI/WindowContent/WindowContent.vue";
import GlobalIcon from "@/components/Global/GlobalIcon.vue";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "TestView",
	data() {
		return {};
	},
	components: {
		WindowFrame,
		WindowContent,
		GlobalIcon,
	},
	methods: {
		showMsgBox() {
			msgCtrl.showMsgBoxYesNo(
				msgCtrl.iconList.trash,
				"測試訊息",
				"這是一則用於測試的提示訊息<br/>這是提示訊息的第二行<br/>這是提示訊息的第三行",
				"Yes",
				msgCtrl.buttonList.danger,
				"No",
				msgCtrl.buttonList.light,
				() => {},
				() => {}
			);
		},
		showNotify() {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.alert,
				"測試通知訊息",
				"這是一個測試的通知訊息，只有一行，長度比欄位長，用於測試超出欄位的處理方式",
				500 * 1000
			);
		},
		showWindow() {
			this.$refs.windowframe.setWindowSize(this.$refs.windowcontent, false);
			this.$refs.windowcontent.showContent();
			setTimeout(() => {
				this.$refs.windowframe.setWindowShow();
			}, 350);
		},
		hideWindow() {
			this.$refs.windowframe.setWindowHidden();
		},
	},
	mounted() {
		var vm = this;
		vm.showNotify();
	},
};
</script>
<style lang="less" scoped>
</style>