import { render, staticRenderFns } from "./FrameBottom.html?vue&type=template&id=3879fec0&scoped=true&"
import script from "./FrameBottom.vue?vue&type=script&lang=js&"
export * from "./FrameBottom.vue?vue&type=script&lang=js&"
import style0 from "./FrameBottom.css?vue&type=style&index=0&id=3879fec0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3879fec0",
  null
  
)

export default component.exports