var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuWrapper",class:[_vm.layoutstatus]},[_c('div',{staticClass:"menuBg"}),_c('div',{staticClass:"menuContent"},[_c('div',{staticClass:"itemTitle"},[_vm._v("支出")]),_c('div',{staticClass:"itemMenu L1",class:{ active: _vm.frameCheck('Home') }},[_c('div',{staticClass:"itemMenuContent",on:{"click":function($event){return _vm.frameChange('Home')}}},[_c('i',{staticClass:"fa-solid fa-table-list"}),_vm._v(" 總覽清單 ")])]),_c('div',{staticClass:"itemMenu L1 folder",class:{
					open: _vm.getFolderStatus('byMonth').value,
					close: !_vm.getFolderStatus('byMonth').value,
				}},[_c('div',{staticClass:"itemMenuContent",on:{"click":function($event){return _vm.taggleFolder('byMonth')}}},[_c('i',{staticClass:"fa-solid fa-calendar"}),_vm._v(" 依月份 ")]),_vm._l((_vm.calendarData),function(year){return _c('div',{key:year.title,staticClass:"itemMenu L2 folder",class:{
						open: _vm.getFolderStatus('Year.' + year.title).value,
						close: !_vm.getFolderStatus('Year.' + year.title).value,
					}},[_c('div',{staticClass:"itemMenuContent",on:{"click":function($event){return _vm.taggleFolder('Year.' + year.title, 'Year')}}},[_c('i',{staticClass:"fa-regular fa-calendar"}),_vm._v(" "+_vm._s(year.title)+" 年 ")]),_vm._l((year.month),function(month){return _c('div',{key:year.title + month.title,staticClass:"itemMenu L3",class:{ active: _vm.frameCheck(year.title + '.' + month.title) }},[_c('div',{staticClass:"itemMenuContent",on:{"click":function($event){return _vm.frameChange(year.title + '.' + month.title)}}},[_c('i',{staticClass:"fa-regular fa-calendar-days"}),_vm._v(" "+_vm._s(year.title)+" 年 "+_vm._s(month.title)+" 月 ")])])})],2)})],2),_c('div',{staticClass:"itemMenu L1",class:{ active: _vm.frameCheck('Fixed') }},[_c('div',{staticClass:"itemMenuContent",on:{"click":function($event){return _vm.frameChange('Fixed')}}},[_c('i',{staticClass:"fa-solid fa-money-check-dollar"}),_vm._v(" 固定支出 ")])]),_c('div',{staticClass:"itemMenu L1",class:{ active: _vm.frameCheck('Value') }},[_c('div',{staticClass:"itemMenuContent",on:{"click":function($event){return _vm.frameChange('Value')}}},[_c('i',{staticClass:"fa-solid fa-money-bill-1-wave"}),_vm._v(" 非固定支出 ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }