<template src="./FrameBottom.html"></template>

<script>
import store from "@/store";
import IncomeMenu from "@/components/Menu/IncomeMenu/IncomeMenu.vue";
import ExpensesMenu from "@/components/Menu/ExpensesMenu/ExpensesMenu.vue";
import OverviewMenu from "@/components/Menu/OverviewMenu/OverviewMenu.vue";
import SettingMenu from "@/components/Menu/SettingMenu/SettingMenu.vue";
export default {
	name: "FrameBottom",
	props: {},
	data() {
		return {
			isDisableBlur: false,
			overlayStatus: "",
			showStatus: "",
			targetMenuName: "",
			targetSubMenuName: "",
			lastestEl: null,
			menuActiveData: {
				height: 0,
				width: 0,
				left: 0,
				top: 0,
			},
			mainMenuStatus: "show",
			subMenuStatus: "hide",
			defaultWrapperHeight: 78,
			wrapperHeight: 78,
			wrapperHeightData: {
				Overview: 192,
				Expenses: 560,
				Income: 560,
				Setting: 560,
			},
		};
	},
	components: {
		IncomeMenu,
		ExpensesMenu,
		OverviewMenu,
		SettingMenu,
	},
	computed: {
		userEntity: {
			get() {
				return store.state.rootStore.userEntity;
			},
		},
		frameName: {
			get() {
				return store.state.frameName;
			},
		},
		isModalBoxOpen: {
			get() {
				return store.getters.isModalBoxOpen;
			},
		},
	},
	watch: {
		frameName() {
			var vm = this;
			vm.onSubBack();
		},
		isModalBoxOpen(newValue) {
			var vm = this;
			vm.setModalBoxOpen(newValue);
		},
	},
	methods: {
		showLayout() {
			var vm = this;
			vm.showStatus = "show";
		},
		hideLayout() {
			var vm = this;
			vm.showStatus = "hide";
		},
		onClickMenuItem(name) {
			var vm = this;
			vm.targetMenuName = name;
			vm.onSubShow();
		},
		moveActiveItem(top, left, width, height) {
			var vm = this;
			vm.menuActiveData.top = top;
			vm.menuActiveData.left = left;
			vm.menuActiveData.height = height;
			vm.menuActiveData.width = width;
		},
		onMenuItemActive() {
			var vm = this;
			if (vm.$refs.layoutMenu == null) return;
			let el = vm.$refs.layoutMenu.querySelector(".menuItem.active");
			if (el == null) return;
			if (vm.lastestEl == el.dataset.name) return;
			vm.lastestEl = el.dataset.name;
			var target = el.getBoundingClientRect();
			vm.moveActiveItem(0, target.x, target.width, target.height);
		},
		isMenuItemActive(name) {
			var vm = this;
			let result = true;
			if (vm.frameName.toUpperCase().indexOf(name.toUpperCase() + ".") < 0)
				result = false;
			if (vm.targetMenuName != null && vm.targetMenuName != "") {
				if (vm.targetMenuName == name) result = true;
				else result = false;
			}
			if (result) {
				setTimeout(() => {
					vm.onMenuItemActive();
				}, 150);
			}
			return result;
		},
		onSubShow() {
			var vm = this;
			//vm.isDisableBlur = true;
			vm.overlayStatus = "show";
			setTimeout(() => {
				vm.targetSubMenuName = vm.targetMenuName;
				setTimeout(() => {
					let h = vm.wrapperHeightData[vm.targetMenuName];
					vm.setWrapperHeight(h);
				}, 50);
				setTimeout(() => {
					vm.mainMenuStatus = "hide";
				}, 100);
				setTimeout(() => {
					vm.subMenuStatus = "show";
				}, 600);
				setTimeout(() => {
					vm.isDisableBlur = false;
				}, 500);
			}, 100);
		},
		onSubBack() {
			var vm = this;
			vm.subMenuStatus = "hide";
			vm.overlayStatus = "hide";
			setTimeout(() => {
				setTimeout(() => {
					vm.setWrapperHeight(vm.defaultWrapperHeight);
				}, 250);
				setTimeout(() => {
					vm.mainMenuStatus = "show";
				}, 250);
				setTimeout(() => {
					vm.targetMenuName = "";
					vm.targetSubMenuName = "";
				}, 350);
			}, 100);
		},
		setWrapperHeight(input) {
			var vm = this;
			vm.wrapperHeight = input;
		},
		calMenuHeight() {
			var vm = this;
			let h = (window.innerHeight / 3) * 2;
			vm.wrapperHeightData.Expenses = parseInt(h.toString());
			vm.wrapperHeightData.Income = parseInt(h.toString());
			vm.wrapperHeightData.Setting = parseInt(h.toString());
			if (window.navigator.standalone) {
				vm.defaultWrapperHeight = vm.wrapperHeight =
					78 +
					parseInt(
						getComputedStyle(document.documentElement).getPropertyValue("--sab")
					);
			}
		},
		setModalBoxOpen(value) {
			var vm = this;
			if (value) {
				vm.isDisableBlur = true;
				store.state.debugData.FrameTopModal = vm.isDisableBlur;
			} else
				setTimeout(() => {
					vm.isDisableBlur = false;
					store.state.debugData.FrameTopModal = vm.isDisableBlur;
				}, 750);
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		vm.calMenuHeight();
		setTimeout(() => {
			vm.showLayout();
		}, 350);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FrameBottom.css">
</style>
