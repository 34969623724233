export default {
	getUserEntity: getUserEntity,
	setUserEntity: setUserEntity,
	setUserEntityClear: setUserEntityClear,
};

function getUserEntity() {
	return localStorage.getItem("userEntity")
		? JSON.parse(localStorage.getItem("userEntity")).UserEntity
		: null;
}

function setUserEntity(userEntity) {
	let data = {
		UserEntity: userEntity,
		CreateTime: new Date().now,
	};
	localStorage.setItem("userEntity", JSON.stringify(data));
}

function setUserEntityClear() {
	localStorage.removeItem("userEntity");
}
