<template src="./WindowContent.html"></template>
  
<script>
export default {
	name: "WindowContent",
	props: ["padding"],
	data() {
		return {
			status: "hide",
		};
	},
	computed: {},
	methods: {
		showContent() {
			var vm = this;
			vm.status = "show";
		},
		hideContent() {
			var vm = this;
			vm.status = "hide";
		},
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./WindowContent.css">
</style>