<template>
	<div class="loadwrapper">
		<div class="loadspin">
			<div class="loadspinborder"></div>
			<div class="loadspinwait">
				<div class="loadspinwaitbg"></div>
			</div>
		</div>
	</div>
</template>
  
<script>
export default {
	name: "LoadSpin",
	props: {},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	methods: {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.loadwrapper {
	position: relative;
	height: 24px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}
.loadspin {
	position: relative;
	height: 8px;
	width: 100%;
	opacity: 1;
	border-radius: 18px;
	background: var(--bg-rgba-bg-25);
	backdrop-filter: blur(24px);
	box-shadow: var(--shadow-progress-150);
	transition: 0.5s;
}
.loadspinborder {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border: 2px solid rgba(0, 0, 0, 0.15);
	border-radius: 12px;
}
.loadspinwait {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
}
.loadspinwaitbg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 50%;
	filter: blur(8px);
	background: rgb(255, 255, 255);
	background: linear-gradient(
		90deg,
		var(--bg-rgba-bg-0) 0%,
		rgba(0, 0, 255, 0.5) 40%,
		rgba(0, 0, 255, 1) 80%,
		rgba(0, 0, 255, 1) 90%,
		var(--bg-rgba-bg-0) 100%
	);
	animation: loadspinwaitbg-spin 1.5s ease-in-out infinite;
}
@keyframes loadspinwaitbg-spin {
	0% {
		transform: translateX(-200%);
	}
	100% {
		transform: translateX(200%);
	}
}
</style>