<template src="./FrameLeft.html"></template>

<script>
import store from "@/store";
import IncomeMenu from "@/components/Menu/IncomeMenu/IncomeMenu.vue";
import ExpensesMenu from "@/components/Menu/ExpensesMenu/ExpensesMenu.vue";
import OverviewMenu from "@/components/Menu/OverviewMenu/OverviewMenu.vue";
import SettingMenu from "@/components/Menu/SettingMenu/SettingMenu.vue";
export default {
	name: "FrameLeft",
	props: {},
	data() {
		return {
			layoutStatus: "",
			displayStatus: "none",
			menuStatus: "menuOut",
		};
	},
	components: {
		IncomeMenu,
		ExpensesMenu,
		OverviewMenu,
		SettingMenu,
	},
	computed: {
		userEntity: {
			get() {
				return store.state.rootStore.userEntity;
			},
		},
		menuStoreStatus: {
			get() {
				return store.state.menuStatus;
			},
		},
	},
	watch: {
		menuStoreStatus() {
			var vm = this;
			vm.changeMenuStatus();
		},
	},
	methods: {
		showLayout() {
			var vm = this;
			vm.displayStatus = "";
			setTimeout(() => {
				vm.layoutStatus = "show";
			}, 50);
		},
		hideLayout() {
			var vm = this;
			vm.layoutStatus = "hide";
			setTimeout(() => {
				vm.displayStatus = "none";
			}, 400);
		},
		changeMenuStatus() {
			var vm = this;
			if (vm.menuStoreStatus == "open") {
				vm.menuStatus = "menuIn";
			} else {
				vm.menuStatus = "menuOut";
			}
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		setTimeout(() => {
			vm.showLayout();
		}, 150);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FrameLeft.css">
</style>
