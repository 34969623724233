import axios from "axios";
import userToken from "@/utils/userToken";
import store from "../store";

var axiosRequstCount = 0;

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		"Cache-Control": "no-cache",
		pragma: "no-cache",
		post: {
			"Content-Type": "application/json;charset=UTF-8",
		},
	},
});

service.interceptors.request.use(
	(config) => {
		let useSpin = true;
		axiosRequstCount++;

		if (!config.showSpin) useSpin = false;

		if (useSpin) {
			store.dispatch("rootStore/progressInfoIn");
		}
		config.headers["SessionToken"] = userToken.getUserToken().UserToken;
		return config;
	},
	(error) => {
		console.log(error);
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	(response) => {
		axiosRequstCount--;
		if (axiosRequstCount == 0) {
			store.dispatch("rootStore/progressInfoOut");
		}
		const res = response.data;
		return res;
	},
	(error) => {
		axiosRequstCount--;
		if (axiosRequstCount == 0) {
			store.dispatch("rootStore/progressInfoOut");
		}
		console.log("axios client error :" + error);
		return Promise.reject(error);
	}
);

export default service;
