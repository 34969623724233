import moment from "moment";
import store from "@/store";

export default {
	getTotalFullWeekOfMonth: getTotalFullWeekOfMonth,
	getTotalWeekOfMonth: getTotalWeekOfMonth,
	getMonthListByUser: getMonthListByUser,
};

function getTotalFullWeekOfMonth(year, month) {
	let result = getTotalWeekOfMonth(year, month);
	if (result[0].length != 7) {
		let d = moment(year + "/" + month + "/1").add(-1, "months");
		let mr = getTotalWeekOfMonth(d.format("YYYY"), d.format("M"));
		let lr = mr[mr.length - 1];
		result[0].forEach((x) => {
			lr.push(x);
		});
		result[0] = lr;
	}
	if (result[result.length - 1].length != 7) {
		let d = moment(year + "/" + month + "/1").add(1, "months");
		let mr = getTotalWeekOfMonth(d.format("YYYY"), d.format("M"));
		let lr = mr[0];
		lr.forEach((x) => {
			result[result.length - 1].push(x);
		});
	}
	return result;
}

function getTotalWeekOfMonth(year, month) {
	let result = [];
	let s = moment(year + "/" + month + "/1");
	let e = moment(year + "/" + month + "/1")
		.add(1, "months")
		.add(-1, "days");

	let week = [];
	while (s <= e) {
		let weekInt = s.isoWeekday();
		if (weekInt == 7) weekInt = 0;
		week.push({
			date: s.format("YYYY/MM/DD"),
			weekDay: weekInt,
			year: s.format("YYYY"),
			month: s.format("M"),
			days: s.format("D"),
		});

		if (weekInt == 6) {
			result.push(week);
			week = [];
		}

		s = s.add(1, "days");
	}
	if (week.length != 0) result.push(week);

	return result;
}
function getMonthListByUser() {
	let userEntity = store.state.rootStore.userEntity;
	let result = [];
	if (userEntity == null || !userEntity.IsLogin) return result;

	let startDate = moment(userEntity.StartDate);
	if (startDate > new Date()) return result;

	let maxDate = moment();

	while (startDate <= maxDate) {
		let yearObj = result.filter((x) => {
			return x.title == startDate.format("YYYY");
		});
		if (yearObj == null || yearObj.length == 0) {
			yearObj = {
				title: startDate.format("YYYY"),
				month: [],
			};
			result.push(yearObj);
		} else {
			yearObj = yearObj[0];
		}

		yearObj.month.push({
			title: startDate.format("MM"),
		});

		startDate = moment(startDate).add(1, "months");
	}

	result.forEach((y) => {
		y.month.reverse();
	});
	result.reverse();

	return result;
}
