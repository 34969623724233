<template src="./ModalBox.html"></template>

<script>
import store from "@/store";
export default {
	name: "ModalBox",
	props: ["width", "padding", "height", "scroll", "Name"],
	data() {
		return {
			isModalOpen: false,
			callBack: null,
			modalStatus: "",
			wrapperStatus: "",
			wrapperBgStatus: "",
			modalMoveX: 0,
			modalMoveY: 0,
			modalZoom: 1.2,
			modalPointEvent: "none",
			modalOpacity: 0,
			modalTrans: 0.25,
			modalTiming: "",
			contentMoveX: 0,
			contentMoveY: 0,
			contentZoom: 1.2,
			contentPointEvent: "none",
			contentOpacity: 0,
			contentTrans: 0.25,
			contentTiming: "",
			isDisableBlur: false,
		};
	},
	computed: {
		isWidthPhone: {
			get() {
				let result = false;
				if (window.innerWidth <= 576) result = true;
				return result;
			},
		},
		windowHeight: {
			get() {
				return window.innerHeight;
			},
		},
	},
	methods: {
		showModal() {
			var vm = this;
			vm.isModalOpen = true;
			vm.modalStatus = "show";
			vm.wrapperStatus = "show";
			vm.wrapperBgStatus = "show";

			if (vm.isWidthPhone) {
				vm.isDisableBlur = true;
				vm.modalTiming = "ease-in-out";
				vm.contentTiming = "ease-in-out";
				setTimeout(() => {
					vm.modalTrans = 0;
					vm.modalZoom = 1;
					vm.modalOpacity = 1;
					vm.modalMoveY = vm.windowHeight;
					vm.contentTrans = 0;
					vm.contentZoom = 1;
					vm.contentOpacity = 1;
					vm.contentMoveY = vm.windowHeight;
					setTimeout(() => {
						vm.modalTrans = 0.75;
						vm.modalMoveY = 0;
						vm.contentTrans = 0.75;
						vm.contentMoveY = 0;
						setTimeout(() => {
							vm.isDisableBlur = false;
							vm.modalPointEvent = "all";
							vm.contentPointEvent = "all";
						}, 750);
					}, 100);
				}, 50);
			} else {
				vm.modalTiming = "";
				vm.contentTiming = "";
				setTimeout(() => {
					vm.modalTrans = 0.25;
					vm.modalZoom = 0.95;
					vm.modalOpacity = 1;
					vm.contentTrans = 0.25;
					vm.contentZoom = 0.95;
					vm.contentOpacity = 1;
					setTimeout(() => {
						vm.modalTrans = 0.25;
						vm.modalZoom = 1;
						vm.modalOpacity = 1;
						vm.modalPointEvent = "all";
						vm.contentTrans = 0.25;
						vm.contentZoom = 1.0;
						vm.contentOpacity = 1;
						vm.contentPointEvent = "all";
					}, 250);
				}, 100);
			}
		},
		hideModal() {
			var vm = this;
			vm.isModalOpen = false;
			vm.wrapperBgStatus = "hide";

			if (vm.isWidthPhone) {
				vm.modalTiming = "ease-in-out";
				vm.contentTiming = "ease-in-out";
				vm.isDisableBlur = true;
				vm.modalTrans = 0;
				vm.modalZoom = 1;
				vm.modalOpacity = 1;
				vm.modalMoveY = 0;
				vm.modalPointEvent = "none";
				vm.contentTrans = 0;
				vm.contentZoom = 1;
				vm.contentOpacity = 1;
				vm.contentMoveY = 0;
				vm.contentPointEvent = "none";
				setTimeout(() => {
					vm.modalTrans = 0.75;
					vm.modalMoveY = vm.windowHeight;
					vm.contentTrans = 0.75;
					vm.contentMoveY = vm.windowHeight;
					setTimeout(() => {
						vm.isDisableBlur = false;
						vm.modalStatus = "hide";
						vm.wrapperStatus = "hide";
						if (vm.callBack != null) vm.callBack();
					}, 750);
				}, 100);
			} else {
				vm.modalTiming = "";
				vm.contentTiming = "";
				vm.modalTrans = 0.25;
				vm.modalZoom = 0.95;
				vm.modalOpacity = 0.5;
				vm.modalPointEvent = "none";
				vm.contentTrans = 0.25;
				vm.contentZoom = 0.95;
				vm.contentOpacity = 1;
				vm.contentPointEvent = "none";
				setTimeout(() => {
					vm.modalTrans = 0.15;
					vm.modalZoom = 1.2;
					vm.modalOpacity = 0;
					vm.contentTrans = 0.25;
					vm.contentZoom = 1.2;
					vm.contentOpacity = 0;
					vm.modalPointEvent = "none";
				}, 250);
				setTimeout(() => {
					vm.modalStatus = "hide";
					vm.wrapperStatus = "hide";
					if (vm.callBack != null) vm.callBack();
				}, 500);
			}
		},
		onWrapperClick() {
			var vm = this;
			vm.modalTrans = 0.25;
			vm.contentTrans = 0.25;
			vm.modalZoom = 1.01;
			vm.contentZoom = 1.01;
			setTimeout(() => {
				vm.modalZoom = 1.0;
				vm.contentZoom = 1.0;
			}, 250);
		},
	},
	mounted: function () {
		var vm = this;
		if (!vm.Name) return;
		store.dispatch("modalBoxAdd", {
			name: vm.Name,
			content: vm.$parent,
			modal: vm,
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./ModalBox.css">
</style>
