<template src="./RegisterWindow.html"></template>
  
<script>
import GlobalIconTrans from "@/components/Global/GlobalIconTrans.vue";
import msgCtrl from "@/utils/msgCtrl";
import registerApi from "@/api/register";
import userToken from "@/utils/userToken";
export default {
	name: "RegisterWindow",
	props: {},
	data() {
		return {
			iconLargeSize: 192,
			contentTop: 220,
			pageName: "welcome",
			pageStatus: {
				welcome: "goshow",
				license: "gohide",
				email: "gohide",
				setting: "gohide",
				finish: "gohide",
			},
			btnSendStatus: "normal",
			btnVerifyStatus: "normal",
			btnRegisterStatus: "normal",
			finishStatus: "Success",
			email: "randkao@gmail.com",
			emailShow: "randkao@gmail.com",
			displayName: "",
			verifyUID: "",
			sendStatus: "normal",
			activeKeyList: {
				key1: "",
				key2: "",
				key3: "",
				key4: "",
				key5: "",
				key6: "",
			},
		};
	},
	components: {
		GlobalIconTrans,
	},
	computed: {
		activeKey1: {
			get() {
				var vm = this;
				return vm.activeKeyList.key1;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key1 = value.toUpperCase();
			},
		},
		activeKey2: {
			get() {
				var vm = this;
				return vm.activeKeyList.key2;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key2 = value.toUpperCase();
			},
		},
		activeKey3: {
			get() {
				var vm = this;
				return vm.activeKeyList.key3;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key3 = value.toUpperCase();
			},
		},
		activeKey4: {
			get() {
				var vm = this;
				return vm.activeKeyList.key4;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key4 = value.toUpperCase();
			},
		},
		activeKey5: {
			get() {
				var vm = this;
				return vm.activeKeyList.key5;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key5 = value.toUpperCase();
			},
		},
		activeKey6: {
			get() {
				var vm = this;
				return vm.activeKeyList.key6;
			},
			set(value) {
				var vm = this;
				vm.activeKeyList.key6 = value.toUpperCase();
			},
		},
		activeKey: {
			get() {
				var vm = this;
				return (
					vm.activeKey1 +
					vm.activeKey2 +
					vm.activeKey3 +
					vm.activeKey4 +
					vm.activeKey5 +
					vm.activeKey6
				);
			},
		},
		isCanVerify: {
			get() {
				var vm = this;
				let result = true;
				if (vm.activeKey1 == null || vm.activeKey1 == "") result = false;
				if (vm.activeKey2 == null || vm.activeKey2 == "") result = false;
				if (vm.activeKey3 == null || vm.activeKey3 == "") result = false;
				if (vm.activeKey4 == null || vm.activeKey4 == "") result = false;
				if (vm.activeKey5 == null || vm.activeKey5 == "") result = false;
				if (vm.activeKey6 == null || vm.activeKey6 == "") result = false;

				return result;
			},
		},
		isCanRegister: {
			get() {
				var vm = this;
				let result = true;
				if (vm.displayName.length < 4) result = false;
				if (vm.displayName.length > 15) result = false;
				return result;
			},
		},
	},
	watch: {
		pageName(newValue, oldValue) {
			var vm = this;

			if (newValue == "welcome" || newValue == "finish") {
				vm.contentTop = 220;
				vm.$refs.iconTrans.setBarToIcon();
			} else {
				vm.contentTop = 32;
				vm.$refs.iconTrans.setIconToBar();
			}

			let indexOld = -1;
			let indexNew = -1;
			let statusOld = "gohide";
			let statusNew = "goshow";
			let keys = Object.keys(vm.pageStatus);
			keys.forEach((key, i) => {
				if (key == oldValue) indexOld = i;
				if (key == newValue) indexNew = i;
			});
			if (indexOld < indexNew) {
				statusOld = "gohide";
				statusNew = "goshow";
			} else {
				statusOld = "backhide";
				statusNew = "backshow";
			}
			keys.forEach((key) => {
				if (key == oldValue) {
					vm.pageStatus[key] = statusOld;
					return;
				}
				if (key == newValue) {
					vm.pageStatus[key] = statusNew;
					return;
				}
			});
		},
	},
	methods: {
		setPage(value) {
			var vm = this;
			vm.pageName = value;
			vm.pageBack = false;
		},
		setPageBack(value) {
			var vm = this;
			vm.pageName = value;
			vm.pageBack = true;
		},
		setInital(value) {
			var vm = this;
			vm.email = value;
			vm.emailShow = value;
			vm.setPage("welcome");
			vm.btnSendStatus = "normal";
			vm.btnVerifyStatus = "normal";
			vm.btnRegisterStatus = "normal";
			vm.finishStatus = "normal";
			vm.displayName = "";
			vm.verifyUID = "";
			vm.sendStatus = "normal";
			vm.activeKey1 =
				vm.activeKey2 =
				vm.activeKey3 =
				vm.activeKey4 =
				vm.activeKey5 =
				vm.activeKey6 =
					"";
		},
		onLicenseBack() {
			var vm = this;
			msgCtrl.showMsgBoxYesNo(
				msgCtrl.iconList.quest,
				"放棄註冊",
				"確定要放棄註冊嗎？我們將離開註冊界面返回到登入畫面。",
				"繼續註冊",
				msgCtrl.buttonList.primary,
				"放棄註冊",
				msgCtrl.buttonList.danger,
				() => {},
				() => {
					vm.setPage("welcome");
					setTimeout(() => {
						vm.$parent.$parent.$parent.switchLogin();
					}, 450);
				}
			);
		},
		onFinishClick() {
			var vm = this;
			vm.$parent.$parent.$parent.callGetUserEntity();
			setTimeout(() => {
				vm.setPage("welcome");
			}, 3000);
		},
		setWindowSizeMatch() {
			var vm = this;
			if (window.screen.width <= 576) {
				vm.iconLargeSize = 128;
			} else {
				vm.iconLargeSize = 128;
			}
		},
		setSendMailBtnStatus(value) {
			var vm = this;
			vm.btnSendStatus = value;
		},
		setSendStatus(value) {
			var vm = this;
			vm.sendStatus = value;
		},
		setVerifyBtnStatus(value) {
			var vm = this;
			vm.btnVerifyStatus = value;
		},
		setRegisterBtnStatus(value) {
			var vm = this;
			vm.btnRegisterStatus = value;
		},
		callVerifyEmail() {
			var vm = this;

			vm.activeKey1 =
				vm.activeKey2 =
				vm.activeKey3 =
				vm.activeKey4 =
				vm.activeKey5 =
				vm.activeKey6 =
					"";

			vm.setSendMailBtnStatus("wait");
			registerApi
				.verifyEmail({
					Email: vm.email,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "NotEmail") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"檢測錯誤",
								"目前輸入的 Email 帳號不符合格式，如果錯誤持續發生，請重新整理並再次重試",
								msgCtrl.notifyDelay.default
							);
							vm.setSendMailBtnStatus("normal");
							return;
						}
						if (res.Action == "Timeout") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證碼已過期",
								"您的驗證碼已經逾期，請點擊「發送驗證碼」重新取得新的驗證碼",
								msgCtrl.notifyDelay.default
							);
							vm.setSendMailBtnStatus("normal");
							return;
						}
						if (res.Action == "Registed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"檢測錯誤",
								"目前輸入的 Email 已經註冊過了，請返回登入界面進行登入",
								msgCtrl.notifyDelay.default
							);
							vm.setSendMailBtnStatus("normal");
							return;
						}
						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"發送失敗",
								"發送驗證碼的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.setSendMailBtnStatus("normal");
							return;
						}
						if (res.Action == "Send") {
							vm.setSendMailBtnStatus("send");
							vm.setSendStatus("send");
							vm.verifyUID = res.Data[0];
							setTimeout(() => {
								vm.setSendMailBtnStatus("normal");
							}, 60 * 1000);
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"發送失敗",
							"發送驗證碼的過程發生錯誤，伺服器返回一個邏輯錯誤",
							msgCtrl.notifyDelay.default
						);
						vm.setSendMailBtnStatus("normal");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼發送時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setSendMailBtnStatus("normal");
				})
				.finally(() => {
					if (vm.btnSendStatus == "wait") vm.setSendMailBtnStatus("normal");
				});
		},
		callCheckActiveKey() {
			var vm = this;

			vm.setVerifyBtnStatus("wait");
			registerApi
				.checkActiveKey({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "Verified") {
							vm.setVerifyBtnStatus("normal");
							vm.setPage("setting");
						}
						if (res.Action == "Unverified") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證失敗",
								"請檢查輸入的驗證碼是否與 Email 中收到的驗證碼一致，並請再試一次",
								msgCtrl.notifyDelay.default
							);
							vm.setVerifyBtnStatus("normal");
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"發送失敗",
							"發送驗證碼的過程發生錯誤，伺服器返回一個邏輯錯誤",
							msgCtrl.notifyDelay.default
						);
						vm.setVerifyBtnStatus("normal");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼檢查時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setVerifyBtnStatus("normal");
				})
				.finally(() => {
					if (vm.btnSendStatus == "wait") vm.setVerifyBtnStatus("normal");
				});
		},
		callSetRegData() {
			var vm = this;

			vm.setRegisterBtnStatus("wait");
			registerApi
				.setRegData({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
					DisplayName: vm.displayName,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"註冊失敗",
								res.Message,
								msgCtrl.notifyDelay.default
							);
							vm.setRegisterBtnStatus("normal");
							return;
						}

						if (res.Action == "Timeout") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證已過期",
								"您的驗證資訊已經逾期，請重新進行 Email 驗證",
								msgCtrl.notifyDelay.default
							);
							vm.setPage("email");
							vm.setRegisterBtnStatus("normal");
							return;
						}
						if (res.Action == "Success") {
							vm.finishStatus = "Success";
							vm.setPage("finish");
							return;
						}
						if (res.Action == "SuccessWithLogin") {
							vm.finishStatus = "SuccessWithLogin";
							userToken.setUserToken(res.Data[0]);
							vm.setPage("finish");
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"註冊失敗",
							"進行註冊流程時發生錯誤，請稍後再重試。如果持續發生錯誤，請與我們聯絡",
							msgCtrl.notifyDelay.default
						);
						vm.setVerifyBtnStatus("normal");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行註冊時發生錯誤，請稍後再次重試，如果問題持續發生，請與我們聯絡",
						msgCtrl.notifyDelay.default
					);
					vm.setVerifyBtnStatus("normal");
				})
				.finally(() => {
					if (vm.btnSendStatus == "wait") vm.setVerifyBtnStatus("normal");
				});
		},
		onActiveKeyDown(e) {
			var vm = this;
			if (e.key === "Backspace" || e.key === "Delete") {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value != "") return;
				if (keyNow == 1) return;
				let backName = "key" + (keyNow - 1).toString();
				vm.$refs[backName].focus();
			}
		},
		onActiveKeyUp(e) {
			var vm = this;
			if (e.key === "Backspace" || e.key === "Delete") {
				return;
			} else {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value == "") return;
				if (keyNow == 6) return;
				let backName = "key" + (keyNow + 1).toString();
				vm.$refs[backName].focus();
			}
		},
	},
	created() {
		var vm = this;
		window.addEventListener("resize", this.setWindowAuto);
		vm.setWindowSizeMatch();
	},
	mounted() {},
	destroyed() {
		window.removeEventListener("resize", this.setWindowAuto);
	},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./RegisterWindow.css">
</style>