<template src="./FramePage.html"></template>

<script>
import store from "@/store";
export default {
	name: "FramePage",
	props: ["Name", "Title", "showEvent", "hideEvent"],
	data() {
		return {
			layoutStatus: "",
			showPage: false,
			layoutSplit: "Left",
		};
	},
	components: {},
	computed: {},
	watch: {},
	methods: {
		setSplit(input) {
			var vm = this;
			vm.layoutSplit = input;
		},
		showLayout() {
			var vm = this;
			vm.layoutStatus = "show";
			vm.showPage = true;
			if (vm.showEvent != null) vm.showEvent();
		},
		hideLayout() {
			var vm = this;
			vm.layoutStatus = "";
			vm.showPage = false;
			if (vm.hideEvent != null) vm.hideEvent();
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		if (!vm.Name) return;
		store.dispatch("framePageAdd", {
			name: vm.Name,
			page: vm,
			title: vm.Title,
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FramePage.css">
</style>
