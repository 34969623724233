export default {
	getUserToken: getUserToken,
	setUserToken: setUserToken,
	setUserTokenClear: setUserTokenClear,
};

function getUserToken() {
	return localStorage.getItem("userToken")
		? JSON.parse(localStorage.getItem("userToken"))
		: { UserToken: null, CreateTime: null };
}

function setUserToken(userToken) {
	let data = {
		UserToken: userToken,
		CreateTime: new Date().now,
	};
	localStorage.setItem("userToken", JSON.stringify(data));
}

function setUserTokenClear() {
	localStorage.removeItem("userToken");
}
