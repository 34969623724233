<template src="./MsgBox.html"></template>

<script>
import store from "@/store";
import debounce from "lodash/debounce";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "MsgBox",
	props: {},
	data() {
		return {
			rootOpacity: 0,
			rootTrans: 0.5,
			rootTiming: "",
			wrapperstatus: "",
			wrapperBlur: 0,
			wrapperTrans: 0.5,
			wrapperTiming: "",
			msgZoom: 1.2,
			msgPointEvent: "none",
			msgOpacity: 0,
			msgTrans: 0.5,
			msgTiming: "",
		};
	},
	computed: {
		msgData: {
			get() {
				if (this.msgList.length > 0) return this.msgList[0];
				else return null;
			},
		},
		msgUID: {
			get() {
				if (this.msgData == null) return null;
				return this.msgData.uid;
			},
		},
		msgList: {
			get() {
				return store.state.msgStore.msgBoxList;
			},
		},
		msgCount: {
			get() {
				return store.state.msgStore.msgBoxList.length;
			},
		},
	},
	watch: {
		msgCount(newValue) {
			var vm = this;

			var hideMsg = function () {
				vm.wrapperstatus = "close";
			};
			var _debounce = debounce(hideMsg, 100);
			if (newValue > 0) {
				vm.wrapperstatus = "open";
			} else {
				_debounce();
			}
		},
		wrapperstatus() {
			this.setDim();
		},
		msgUID(newValue) {
			if (newValue != null) this.setMsgIn();
		},
	},
	methods: {
		runMethod(msg, btn) {
			if (btn.method != null) btn.method();
			this.setMsgOut(msg);
		},
		setDim() {
			if (this.wrapperstatus == "open") {
				this.rootOpacity = 1;
				this.rootTrans = 0;

				this.wrapperBlur = 8;
				this.wrapperTrans = 0.45;
				this.wrapperTiming = "ease-in";
				setTimeout(() => {
					this.rootTrans = 1;
					this.wrapperBlur = 4;
					this.wrapperTrans = 0.35;
					this.wrapperTiming = "ease-out";
				}, 450);
			} else {
				this.wrapperBlur = 0;
				this.wrapperTrans = 0.25;
				this.wrapperTiming = "ease-in";
				setTimeout(() => {
					this.rootOpacity = 0;
					this.rootTrans = 1;
				}, 250);
			}
		},
		setMsgIn() {
			this.msgZoom = 0.95;
			this.msgOpacity = 1;
			this.msgPointEvent = "none";
			this.msgTrans = 0.35;
			this.msgTiming = "ease-in";
			setTimeout(() => {
				this.msgZoom = 1;
				this.msgOpacity = 1;
				this.msgPointEvent = "all";
				this.msgTrans = 0.25;
				this.msgTiming = "ease-out";
			}, 350);
		},
		setMsgOut(msg) {
			this.msgZoom = 0.9;
			this.msgOpacity = 1;
			this.msgPointEvent = "none";
			this.msgTrans = 0.15;
			this.msgTiming = "ease-in";

			this.wrapperBlur = 8;
			this.wrapperTrans = 0.15;

			setTimeout(() => {
				this.msgZoom = 1.2;
				this.msgOpacity = 0;
				this.msgPointEvent = "none";
				this.msgTrans = 0.25;
				this.msgTiming = "ease-out";

				if (this.msgCount == 1) {
					this.wrapperBlur = 0;
					this.wrapperTrans = 0.25;
					setTimeout(() => {
						msgCtrl.removeMsgBox(msg);
					}, 250);
				} else {
					this.wrapperBlur = 4;
					this.wrapperTrans = 0.25;
					setTimeout(() => {
						msgCtrl.removeMsgBox(msg);
					}, 250);
				}
			}, 200);
		},
		onWrapperClick() {
			this.msgZoom = 1.03;
			setTimeout(() => {
				this.msgZoom = 1.0;
			}, 250);
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./MsgBox.css">
</style>
