<template src="./LoadingWindow.html"></template>
  
<script>
import LoadSpin from "@/components/Global/LoadSpin.vue";
import GlobalIcon from "@/components/Global/GlobalIcon.vue";
export default {
	name: "LoadingWindow",
	props: {},
	data() {
		return {
			loadText: "正在載入，請稍後...",
		};
	},
	components: {
		LoadSpin,
		GlobalIcon,
	},
	computed: {},
	watch: {},
	methods: {},
};
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./LoadingWindow.css">
</style>