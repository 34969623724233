<template src="./SplitRight.html"></template>

<script>
import frameCtrl from "@/utils/frameCtrl";
export default {
	name: "SplitRight",
	props: ["widthSize"],
	data() {
		return {
			pageStatus: "",
		};
	},
	components: {},
	computed: {
		splitStatus: {
			get() {
				var vm = this;
				return vm.$parent.$parent.layoutSplit;
			},
		},
	},
	watch: {
		splitStatus() {
			var vm = this;
			vm.splitChange();
		},
	},
	methods: {
		setSplit() {
			frameCtrl.goToSplit("Left");
		},
		splitChange() {
			var vm = this;
			if (vm.splitStatus == "Right") {
				vm.pageStatus = "in";
			} else {
				vm.pageStatus = "out";
			}
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./SplitRight.css">
</style>
