<template src="./StoreManageList.html"></template>

<script>
import SplitLeft from "@/components/UI/FrameUI/SplitUI/SplitLeft/SplitLeft.vue";
export default {
	name: "StoreManageList",
	props: {},
	data() {
		return {};
	},
	components: {
		SplitLeft,
	},
	computed: {},
	watch: {},
	filters: {},
	methods: {},
	created() {},
	destroyed() {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./StoreManageList.css">
</style>
