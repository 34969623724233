import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import _ from "lodash";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap-custom.css";
import "./assets/css/frame-content.css";
import "./assets/bubbly-bg";
import moment from "moment";
import numberConvert from "@/utils/numberConvert";

moment.suppressDeprecationWarnings = true;
Vue.config.productionTip = false;
new Vue({
	router,
	store,
	_,
	moment,
	render: (h) => h(App),
}).$mount("#app");

Vue.filter("numberToCurrency", numberConvert.numberToCurrencyNo);
Vue.filter("momentDate", (input) => {
	if (!input) return input;
	return moment(input).format("YYYY年MM月DD日");
});
Vue.filter("momentTime", (input) => {
	if (!input) return input;
	return moment(input).format("YYYY年MM月DD日 HH:mm:ss");
});
Vue.filter("momentDateWeek", (input) => {
	if (!input) return input;
	let d = moment(input);
	let w = "未知";
	switch (moment(input).isoWeekday()) {
		case 1:
			w = "週一";
			break;
		case 2:
			w = "週二";
			break;
		case 3:
			w = "週三";
			break;
		case 4:
			w = "週四";
			break;
		case 5:
			w = "週五";
			break;
		case 6:
			w = "週六";
			break;
		case 7:
			w = "週日";
			break;
		default:
			console.log(moment(input).isoWeekday());
			break;
	}
	let r = d.format("YYYY年MM月DD日") + " " + w;
	return r;
});
