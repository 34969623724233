import { render, staticRenderFns } from "./FixedList.html?vue&type=template&id=20f851e0&scoped=true&"
import script from "./FixedList.vue?vue&type=script&lang=js&"
export * from "./FixedList.vue?vue&type=script&lang=js&"
import style0 from "./FixedList.css?vue&type=style&index=0&id=20f851e0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f851e0",
  null
  
)

export default component.exports