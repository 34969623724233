<template src="./NotifyItem.html"></template>

<script>
export default {
	name: "NotifyItem",
	props: ["inputData"],
	data() {
		return {
			wrapperStatus: "",
			mainStatus: "",
			timer: null,
		};
	},
	computed: {},
	watch: {},
	methods: {
		showItem() {
			var vm = this;
			vm.wrapperStatus = "show";
			setTimeout(() => {
				vm.mainStatus = "show";
			}, 750);
			let delay = 5000;
			if (vm.inputData.delay) delay = vm.inputData.delay;
			vm.timer = setTimeout(() => {
				vm.hideItem();
			}, delay);
		},
		hideItem() {
			var vm = this;
			vm.mainStatus = "hide";
			if (vm.timer != null) clearTimeout(vm.timer);
			setTimeout(() => {
				vm.wrapperStatus = "hide";
			}, 350);
			setTimeout(() => {
				vm.$parent.removeNotify(vm.inputData);
			}, 700);
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		vm.showItem();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./NotifyItem.css">
</style>
