import store from "@/store";
import guid from "@/utils/createGUID";

export default {
	iconList: {
		ok: {
			type: "Custom",
			custom: {
				icon: "fa-solid fa-circle-check",
				color: "text-success",
			},
		},
		error: {
			type: "Custom",
			custom: {
				icon: "fa-solid fa-circle-xmark",
				color: "text-danger",
			},
		},
		alert: {
			type: "Custom",
			custom: {
				icon: "fa-solid fa-triangle-exclamation",
				color: "col-12 text-warning",
			},
		},
		quest: {
			type: "Custom",
			custom: {
				icon: "fa-solid fa-circle-question",
				color: "text-secondary",
			},
		},
		trash: {
			type: "Custom",
			custom: {
				icon: "fa-solid fa-trash-can",
				color: "text-danger",
			},
		},
		disconnect: {
			type: "Custom",
			custom: {
				icon: "fa-solid fa-ethernet",
				color: "text-danger",
			},
		},
		custom: {
			type: "Custom",
			custom: {
				icon: "",
				color: "",
			},
		},
	},
	buttonList: {
		primary: "btn-primary",
		secondary: "btn-secondary",
		success: "btn-xsuccessx",
		danger: "btn-danger",
		warning: "btn-warning",
		info: "btn-info",
		light: "btn-light",
		dark: "btn-dark",
	},
	notifyDelay: {
		default: 7 * 1000,
		long: 10 * 1000,
		short: 5 * 1000,
	},

	showMsgBoxOK: showMsgBoxOK,
	showMsgBoxYesNo: showMsgBoxYesNo,
	showMsgBox: showMsgBox,
	removeMsgBox: removeMsgBox,
	removeAllMsgBox: removeAllMsgBox,

	showNotifyDefault: showNotifyDefault,
	showNotify: showNotify,
	removeNotify: removeNotify,
	removeAllNotify: removeAllNotify,
};

function showMsgBoxOK(icon, title, message, okText, okType, callback) {
	showMsgBox({
		icon: icon,
		msg: {
			title: title,
			message: message,
		},
		btn: [
			{
				text: okText,
				type: okType,
				method: function () {
					if (callback != null) callback();
				},
			},
		],
	});
}

function showMsgBoxYesNo(
	icon,
	title,
	message,
	yesText,
	yesType,
	noText,
	noType,
	yesCallBack,
	noCallBack
) {
	showMsgBox({
		icon: icon,
		msg: {
			title: title,
			message: message,
		},
		btn: [
			{
				text: noText,
				type: noType,
				method: function () {
					if (noCallBack != null) noCallBack();
				},
			},
			{
				text: yesText,
				type: yesType,
				method: function () {
					if (yesCallBack != null) yesCallBack();
				},
			},
		],
	});
}

function showMsgBox(obj) {
	obj.uid = guid.createGUID();
	obj.status = "";
	store.dispatch("msgStore/setMsgBox", obj);
}

function removeMsgBox(obj) {
	store.dispatch("msgStore/removeMsgBox", obj);
}

function removeAllMsgBox() {
	store.dispatch("msgStore/clearMsgBox");
}

/*
{
    icon:{
        type:'' //OK,Error,Alert,Quest,Custom
        ,
        custom:{
            icon:''
            ,color:''
        }
    },
    msg:{
        title:''
        ,message:''
    },
    btn:[
        {
            text:''
            ,type:''
            ,method:function(){}
        }
    ]
}
*/

function showNotifyDefault(icon, title, message, delay) {
	showNotify({
		icon: icon.custom,
		title: title,
		message: message,
		delay: delay,
	});
}

function showNotify(obj) {
	obj.uid = guid.createGUID();
	store.dispatch("msgStore/setNotify", obj);
}

function removeNotify(obj) {
	store.dispatch("msgStore/removeNotify", obj);
}

function removeAllNotify() {
	store.dispatch("msgStore/clearNotify");
}
