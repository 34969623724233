<template>
	<div id="app">
		<GlobalBg />
		<router-view></router-view>
		<MsgBox />
		<NotifyList />
		<ConnectSpin />
		<DebugOverlay />
	</div>
</template>

<script>
import MsgBox from "@/components/UI/MessageUI/MsgBox/MsgBox.vue";
import NotifyList from "@/components/UI/MessageUI/NotifyUI/NotifyList/NotifyList.vue";
import GlobalBg from "@/components/Global/GlobalBg.vue";
import ConnectSpin from "@/components/Global/ConnectSpin.vue";
import DebugOverlay from "@/components/Global/DebugOverlay.vue";

export default {
	components: {
		MsgBox,
		NotifyList,
		GlobalBg,
		ConnectSpin,
		DebugOverlay,
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		setViewPortHeight() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},
	},
	mounted() {
		var vm = this;
		window.addEventListener("resize", () => {
			vm.setViewPortHeight();
		});
		vm.setViewPortHeight();
	},
};
</script>

<style lang="less">
#app {
	font-family: Roboto, "Noto Sans TC", sans-serif;
	color: var(--text-color-normal);
}
</style>
