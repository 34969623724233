import userEntity from "@/utils/userEntity";
export default {
	namespaced: true,
	state: {
		//初始化
		isLoading: false,
		isInitial: false,
		userEntity: null,
		router: {
			startupBack: null,
			loginBack: null,
		},
		//選單開關
		menuStatus: "",
	},
	getters: {},
	mutations: {
		setLoading(state, v) {
			state.isLoading = v;
		},
		setInitial(state, v) {
			state.isInitial = v;
		},
		setUserEntity(state, v) {
			state.userEntity = v;
		},
		setRouterStartupBack(state, v) {
			state.router.startupBack = v;
		},
		setRouterLoginBack(state, v) {
			state.router.loginBack = v;
		},
		setMenuStatus(state, v) {
			state.menuStatus = v;
		},
	},
	actions: {
		progressInfoIn(context) {
			context.commit("setLoading", true);
		},
		progressInfoOut(context) {
			context.commit("setLoading", false);
		},
		programInitial(context) {
			context.commit("setInitial", true);
		},
		userEntityLoad(context, input) {
			userEntity.setUserEntity(input);
			context.commit("setUserEntity", input);
		},
		routerStartupBack(context, input) {
			context.commit("setRouterStartupBack", input);
		},
		routerLoginBack(context, input) {
			context.commit("setRouterLoginBack", input);
		},
		setMenuStatus(context, input) {
			context.commit("setMenuStatus", input);
		},
	},
};
