<template src="./FixedList.html"></template>

<script>
import store from "@/store";
import moment from "moment";
import _ from "lodash";
import SplitLeft from "@/components/UI/FrameUI/SplitUI/SplitLeft/SplitLeft.vue";
import BScroll from "@better-scroll/core";
import MouseWheel from "@better-scroll/mouse-wheel";
import PullDown from "@better-scroll/pull-down";
import ScrollBar from "@better-scroll/scroll-bar";
import modalCtrl from "@/utils/modalCtrl";
import incomeRepo from "@/repos/incomeRepo";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "FixedList",
	props: {},
	data() {
		return {
			sortBy: "UpdateTime",
			sortOrder: "Down",
			selectMode: false,
			listScroll: null,
			listScrollOn: false,
			pullDownStatus: "",
			pullDownText: "pullDown",
			listCtrlStatus: "",
			listCtrlTempNormalStatus: "",
			doMiniEvent: null,
		};
	},
	components: {
		SplitLeft,
	},
	computed: {
		dataList: {
			get() {
				return store.state.incomeData.incomeFixedList;
			},
		},
		filterList: {
			get() {
				var vm = this;
				let result = vm.dataList;
				if (vm.sortBy == "UpdateTime") {
					if (vm.sortOrder == "Down") {
						result.sort((a, b) =>
							moment(a.UpdateTime) < moment(b.UpdateTime) ? 1 : -1
						);
					} else {
						result.sort((a, b) =>
							moment(a.UpdateTime) > moment(b.UpdateTime) ? 1 : -1
						);
					}
				}
				if (vm.sortBy == "CreateTime") {
					if (vm.sortOrder == "Down") {
						result.sort((a, b) =>
							moment(a.CreateTime) < moment(b.CreateTime) ? 1 : -1
						);
					} else {
						result.sort((a, b) =>
							moment(a.CreateTime) > moment(b.CreateTime) ? 1 : -1
						);
					}
				}
				if (vm.sortBy == "Amount") {
					if (vm.sortOrder == "Down") {
						result.sort((a, b) => (a.Amount < b.Amount ? 1 : -1));
					} else {
						result.sort((a, b) => (a.Amount > b.Amount ? 1 : -1));
					}
				}

				return result;
			},
		},
		selectItem: {
			get() {
				return store.state.incomeFixedItem;
			},
		},
		isSelectedItem: {
			get() {
				var vm = this;
				let selectList = vm.dataList.filter((x) => {
					return x.IsSelect;
				});
				if (selectList.length == 0) {
					return false;
				}
				return true;
			},
		},
	},
	watch: {
		dataList: {
			handler() {
				var vm = this;
				if (vm.dataList.length == 0) {
					vm.selectItemChange(null);
					return;
				}
				if (vm.selectItem == null) {
					if (window.innerWidth > 576) vm.selectItemChange(vm.dataList[0]);
					return;
				}
				if (vm.$refs.splitCtrl.splitStatus == "Left") return;
				vm.dataList.forEach((x) => {
					if (x.UID == vm.selectItem.UID) vm.selectItemChange(x);
				});
				vm.setScrollBar();
			},
		},
		listScrollOn(newValue) {
			store.state.debugData.ScrollOn = newValue;
		},
	},
	filters: {},
	methods: {
		setScrollBar() {
			var vm = this;
			if (vm.listScroll != null) vm.listScroll.destroy();
			let target = vm.$refs.listScrollElement;
			if (target == null) return;
			BScroll.use(PullDown);
			BScroll.use(MouseWheel);
			BScroll.use(ScrollBar);
			vm.listScroll = new BScroll(target, {
				scrollY: true,
				scrollbar: true,
				click: true,
				tap: true,
				pullDownRefresh: {
					stop: 64,
					threshold: 64,
				},
				mouseWheel: {
					speed: 20,
					invert: false,
					easeTime: 300,
				},
			});
			vm.listScroll.on("scroll", function () {
				if (this.y > 20) {
					vm.pullDownStatus = "show";
				} else {
					vm.pullDownStatus = "";
				}
				if (this.y >= 64) {
					if (vm.pullDownText != "loading") vm.pullDownText = "refrash";
				} else {
					vm.pullDownText = "pullDown";
				}
				if (this.y <= -80) {
					vm.listCtrlStatus = "mini";
				} else {
					vm.listCtrlStatus = "";
				}
			});
			vm.listScroll.on("pullingDown", () => {
				let startTime = moment();
				vm.pullDownText = "loading";
				vm.setSelectMode(false);
				incomeRepo.incomeFixed.getDataList(() => {
					let timeLeft = 1000 - moment().diff(startTime, "milliseconds");
					if (timeLeft > 0) {
						setTimeout(() => {
							vm.listScroll.finishPullDown();
						}, timeLeft);
					} else vm.listScroll.finishPullDown();
				});
			});
		},
		showEditor() {
			modalCtrl.setModalBoxStatus("IncomeFixedEditor", null, "Show", () => {
				incomeRepo.incomeFixed.getDataList();
			});
		},
		doSelectItemChange(event, input) {
			var vm = this;
			vm.selectItemChange(input);
		},
		selectItemChange(input) {
			var vm = this;
			if (vm.selectMode) {
				input.IsSelect = !input.IsSelect;
			} else {
				store.dispatch("setIncomeFixedItem", input);
				vm.$refs.splitCtrl.setSplit();
			}
		},
		setSortData(name) {
			var vm = this;
			if (vm.sortBy == name) {
				if (vm.sortOrder == "Up") vm.sortOrder = "Down";
				else vm.sortOrder = "Up";
			} else {
				vm.sortBy = name;
				vm.sortOrder = "Down";
			}
		},
		setSelectMode(input) {
			var vm = this;
			vm.selectMode = input;
			if (!vm.selectMode) {
				vm.setSelectAll(false);
			}
		},
		setSelectAll(input) {
			var vm = this;
			vm.dataList.forEach((x) => {
				x.IsSelect = input;
			});
		},
		delSelectItem() {
			var vm = this;
			let selectList = vm.dataList.filter((x) => {
				return x.IsSelect;
			});
			if (selectList.length == 0) {
				vm.setSelectMode(false);
				return;
			}

			msgCtrl.showMsgBoxYesNo(
				msgCtrl.iconList.trash,
				"移除選擇的項目",
				"確定要移除選擇的這 " + selectList.length + " 個項目？",
				"確認移除",
				msgCtrl.buttonList.danger,
				"取消",
				msgCtrl.buttonList.light,
				() => {
					incomeRepo.incomeFixed.delEntityList(selectList, () => {
						vm.setSelectMode(false);
					});
				},
				() => {}
			);
		},
		doSetListCtrlMini() {
			var vm = this;
			vm.listCtrlTempNormalStatus = "tempNormal";
			if (vm.doMiniEvent != null) vm.doMiniEvent();
		},
		setListCtrlMini() {
			var vm = this;
			vm.listCtrlTempNormalStatus = "";
		},
	},
	created() {},
	destroyed() {},
	mounted: function () {
		var vm = this;
		vm.doMiniEvent = _.debounce(vm.setListCtrlMini, 5000);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./FixedList.css">
</style>
