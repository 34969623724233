import incomeApi from "@/api/income";
import store from "@/store";
import msgCtrl from "@/utils/msgCtrl";
import frameCtrl from "@/utils/frameCtrl";

export default {
	getDropdownTypeList: getDropdownTypeList,
	getParamenterCheck: getParamenterCheck,
	getParameterReview: getParameterReview,
	getDataList: getDataList,
	setData: setData,
	delEntity: delEntity,
	delEntityList: delEntityList,
};

function getDropdownTypeList() {
	let result = {};
	result.calType = [
		{ name: "每年為周期", value: "Year" },
		{ name: "每季為周期", value: "Quarter" },
		{ name: "每月為周期", value: "Month" },
		{ name: "每週為周期", value: "Week" },
	];

	result.yearType = [
		{ name: "每年的指定季度", value: "Quarter" },
		{ name: "每年的指定月份", value: "Month" },
		{ name: "每年的指定星期數", value: "Week" },
	];

	result.quarterType = [
		{ name: "季度內的第一個月", value: "FirstMonth" },
		{ name: "季度內的第二個月", value: "SecondMonth" },
		{ name: "季度內的第三個月", value: "ThirdMonth" },
	];

	result.monthType = [
		{ name: "月內的第一週", value: "StartWeekOfMonth" },
		{ name: "月內的最後一週", value: "EndWeekOfMonth" },
		{ name: "月內的指定週數", value: "WeekOfMonth" },
		{ name: "月內的第一天", value: "StartDayOfMonth" },
		{ name: "月內的最後一天", value: "EndDayOfMonth" },
		{ name: "月內的指定日期", value: "DayOfMonth" },
	];

	result.weekType = [
		{ name: "週一", value: "Monday" },
		{ name: "週二", value: "Tuesday" },
		{ name: "週三", value: "Wednesday" },
		{ name: "週四", value: "Thursday" },
		{ name: "週五", value: "Friday" },
		{ name: "週六", value: "Saturday" },
		{ name: "週日", value: "Sunday" },
	];

	return result;
}

function getParamenterCheck(data, finish, failed) {
	incomeApi.incomeFixed
		.checkParameter(data)
		.then((res) => {
			if (res.Success) {
				if (res.Data[0].length != 0) {
					if (failed != null) failed(res.Data[0]);
					return;
				}
				if (res.Data[0].length == 0) {
					if (finish != null) finish();
					return;
				}
			} else {
				msgCtrl.showNotifyDefault(
					msgCtrl.iconList.alert,
					"檢查失敗",
					"檢查參數的過程發生錯誤，伺服器返回一個邏輯錯誤",
					msgCtrl.notifyDelay.default
				);
				return;
			}
		})
		.catch(() => {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.error,
				"連線失敗",
				"連線進行驗證參數時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
				msgCtrl.notifyDelay.default
			);
		})
		.finally(() => {});
}

function getParameterReview(data, callback) {
	incomeApi.incomeFixed
		.reviewParameter(data)
		.then((res) => {
			if (res.Success) {
				if (res.Data[0].length == 0) {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.alert,
						"無法生成預覽",
						"生成預覽的過程中發現錯誤，當前設定的參數無法使用",
						msgCtrl.notifyDelay.default
					);
					return;
				}
				if (res.Data[0].length != 0) {
					if (callback != null) callback(res.Data[0]);
					return;
				}
			} else {
				msgCtrl.showNotifyDefault(
					msgCtrl.iconList.alert,
					"預覽生成失敗",
					"預覽參數的過程發生錯誤，伺服器返回一個邏輯錯誤",
					msgCtrl.notifyDelay.default
				);
				return;
			}
		})
		.catch(() => {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.error,
				"連線失敗",
				"連線進行預覽生成時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
				msgCtrl.notifyDelay.default
			);
		})
		.finally(() => {});
}

function getDataList(callback) {
	incomeApi.incomeFixed
		.getDataList()
		.then((res) => {
			if (res.Success) {
				store.dispatch("setIncomeFixedList", res.Data[0]);
				if (callback != null) callback();
			} else {
				msgCtrl.showNotifyDefault(
					msgCtrl.iconList.alert,
					"取得固定收入列表失敗",
					"取得固定收入列表的過程發生錯誤，伺服器返回一個邏輯錯誤",
					msgCtrl.notifyDelay.default
				);
				return;
			}
		})
		.catch(() => {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.error,
				"連線失敗",
				"連線取得固定收入列表時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
				msgCtrl.notifyDelay.default
			);
		})
		.finally(() => {});
}

function setData(data, callback) {
	incomeApi.incomeFixed
		.setEntity(data)
		.then((res) => {
			if (res.Success) {
				if (callback != null) callback();
			} else {
				msgCtrl.showNotifyDefault(
					msgCtrl.iconList.alert,
					"儲存失敗",
					res.Message,
					msgCtrl.notifyDelay.default
				);
				return;
			}
		})
		.catch(() => {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.error,
				"連線失敗",
				"連線進行儲存時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
				msgCtrl.notifyDelay.default
			);
		})
		.finally(() => {});
}

function delEntity(input) {
	incomeApi.incomeFixed
		.delEntity(input)
		.then((res) => {
			if (res.Success) {
				store.dispatch("setIncomeFixedItem", null);
				frameCtrl.goToSplit("Left");
				exports.default.incomeFixed.getDataList();
			} else {
				msgCtrl.showNotifyDefault(
					msgCtrl.iconList.alert,
					"移除失敗",
					"移除固定收入項目的過程發生錯誤，伺服器返回一個邏輯錯誤",
					msgCtrl.notifyDelay.default
				);
				return;
			}
		})
		.catch(() => {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.error,
				"連線失敗",
				"連線移除固定收入項目時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
				msgCtrl.notifyDelay.default
			);
		})
		.finally(() => {});
}

function delEntityList(input, callback) {
	incomeApi.incomeFixed
		.delEntityList(input)
		.then((res) => {
			if (res.Success) {
				store.dispatch("setIncomeFixedItem", null);
				frameCtrl.goToSplit("Left");
				exports.default.incomeFixed.getDataList();
				if (callback != null) callback();
			} else {
				msgCtrl.showNotifyDefault(
					msgCtrl.iconList.alert,
					"移除失敗",
					"移除固定收入項目的過程發生錯誤，伺服器返回一個邏輯錯誤",
					msgCtrl.notifyDelay.default
				);
				return;
			}
		})
		.catch(() => {
			msgCtrl.showNotifyDefault(
				msgCtrl.iconList.error,
				"連線失敗",
				"連線移除固定收入項目時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
				msgCtrl.notifyDelay.default
			);
		})
		.finally(() => {});
}
