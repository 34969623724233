import store from "@/store";
import router from "@/router";
import encrypt from "@/utils/encrypt";

export default {
	setModalBoxStatus: setModalBoxStatus,
	setModalBox: setModalBox,
	getModalBoxFromUrl: getModalBoxFromUrl,
};

function setModalBoxStatus(name, input, status, callBack) {
	let data = store.state.modalBoxList.filter((x) => {
		return x.name == name;
	});
	if (data == null || data.length == 0) return;
	data = data[0];
	data.modal.callBack = callBack;
	store.dispatch("setModalBoxInput", input);
	encrypt.encryptAES(name, process.env.VUE_APP_FRAME_KEY).then(
		(result) => {
			let query = JSON.parse(JSON.stringify(router.currentRoute.query));
			if (status == "Hide") {
				history.go(-1);
				return;
			} else {
				let aesKey = router.currentRoute.query.modalbox;
				if (aesKey) {
					encrypt
						.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
						.then((nowName) => {
							if (nowName != name) {
								query.modalbox = result;
								router.push({
									name: "home",
									query: query,
								});
							}
						})
						.catch((err) => {
							alert(err.message);
						});
				} else {
					query.modalbox = result;
					router.push({
						name: "home",
						query: query,
					});
				}
			}
		},
		(err) => {
			console.log(err);
		}
	);
}

function setModalBox(name, status) {
	encrypt.encryptAES(name, process.env.VUE_APP_FRAME_KEY).then(
		(result) => {
			let query = JSON.parse(JSON.stringify(router.currentRoute.query));
			if (status == "Hide") {
				history.go(-1);
				return;
			} else {
				let aesKey = router.currentRoute.query.modalbox;
				if (aesKey) {
					encrypt
						.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
						.then((nowName) => {
							if (nowName != name) {
								query.modalbox = result;
								router.push({
									name: "home",
									query: query,
								});
							}
						})
						.catch((err) => {
							alert(err.message);
						});
				} else {
					query.modalbox = result;
					router.push({
						name: "home",
						query: query,
					});
				}
			}
		},
		(err) => {
			console.log(err);
		}
	);
}

function getModalBoxFromUrl() {
	let aesKey = router.currentRoute.query.modalbox;
	if (!aesKey) {
		let data = store.state.modalBoxList.filter((x) => {
			return x.modal.isModalOpen;
		});
		data.forEach((x) => {
			x.content.hideModal();
		});
		return;
	}
	encrypt
		.decryptAES(aesKey, process.env.VUE_APP_FRAME_KEY)
		.then((result) => {
			let data = store.state.modalBoxList.filter((x) => {
				return x.name == result;
			});
			if (data == null || data.length == 0) return;
			data = data[0];

			if (!data.modal.isModalOpen)
				data.content.showModal(store.state.modalBoxInput);
		})
		.catch((err) => {
			alert(err.message);
		});
}
